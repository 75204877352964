.verification-page {
  padding: 118px 0;
  background-color: #faf8f8;

  .text-content {
    text-align: center;

    h2 {
      font-family: Co Headline Bold;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: var(--text-teal);
      margin: 0 0 13px;
    }

    strong {
      color: var(--text-teal);
      font-size: 18px;
      line-height: 26px;
      font-family: Co Headline Bold;
      margin-bottom: 29px;
    }
  }
  p.text-head {
    text-align: center;
    font-size: 11px;
    span {
      color: var(--text-teal);
      font-weight: 700;
    }
  }

  .verification-code {
    margin: 30px 0 23px;

    .textBoxs {
      display: flex;
      justify-content: center;
      input {
        background-color: var(--teal-bg);
        border-radius: 50px;
        width: 80px;
        height: 80px;
        color: var(--text-white);
        font-size: 36px;
        line-height: 17px;
        // font-family: Co Headline Bold;
        font-weight: bold;
        outline: 0;
        border: 0;
        box-shadow: 0px 1px 3px #00000029;
        display: flex;
        margin: 0 12px 0 0;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }
        &::-webkit-input-placeholder {
          color: var(--text-teal);
          height: 4px;
        }
        &.inputViewCompleted:focus {
          outline: none;
        }
      }
    }
  }

  .resent-btn {
    button {
      &.primary-btn {
        border-radius: 30px;
        width: 100%;
        height: 50px;
        background-color: var(--teal-bg);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        color: var(--text-white);
        border: 0;
        font-size: 16px;
        font-family: Lato-Bold;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        outline: none;
        margin-bottom: 20px;

        span {
          position: absolute;
          right: 22px;

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .group-btn {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      width: 49%;
      button {
        &.primary-btn {
          border-radius: 30px;
          width: 100%;
          height: 50px;
          background-color: var(--teal-bg);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
          border: 0;
          color: var(--text-white);
          font-size: 16px;
          line-height: 13px;
          font-family: Lato-Bold;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          outline: none;
          margin-bottom: 20px;
          &.sm.Back {
            span {
              left: 0;
              right: 88%;
              transform: rotate(180deg);
              img {
                vertical-align: baseline;
              }
            }
          }
          &.sm.Continue {
            background-color: var(--teal-bg);
          }

          span {
            position: absolute;
            right: 22px;

            img {
              vertical-align: middle;
            }
          }
        }
      }
    }
    button {
      &.primary-btn {
        border-radius: 30px;
        width: 49%;
        height: 50px;
        background-color: var(--teal-bg);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        border: 0;
        color: #fff;
        font-size: 14px;
        line-height: 13px;
        font-family: Lato-Bold;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        outline: none;
        margin-bottom: 20px;
        &.sm.Back {
          span {
            left: 0;
            right: 88%;
            transform: rotate(180deg);
            img {
              vertical-align: baseline;
            }
          }
        }
        &.sm.Continue {
          background-color: var(--teal-bg);
        }

        span {
          position: absolute;
          right: 22px;

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .verification-page {
    .group-btn {
      a {
        button {
          &.primary-btn {
            &.sm.Back {
              span {
                right: 70%;
              }
            }
            &.sm.Continue {
              width: 100%;
            }
          }
        }
      }
      button {
        &.primary-btn {
          &.sm.Back {
            width: 93%;
            span {
              right: 65%;
            }
          }
          &.sm.Continue {
            width: 91%;
          }
        }
      }
    }
  }
}
@media only screen and (max-device-width: 360px) {
  .verification-page .verification-code .textBoxs input {
    width: 70px;
    height: 70px;
    font-size: 33px;
  }
}
