.footer {
    .footer-top {
        background-color: var(--teal-bg);
        padding: 20px 0;
        box-shadow: 0px -2px 6px #00000029;

        .footer-logo {
            text-align: center;
            margin-bottom: 0px;
            a {
                img {
                    width: 30%;
                }
            }
        }

        .footer-text {
            ul {
                margin: 0;
                padding: 0;
                text-align: center;

                li {
                    list-style: none;
                    margin-bottom: 12px;

                    a {
                        text-decoration: none;
                        color: var(--text-white);
                        font-family: Lato-Regular;
                        font-size: 14px;
                        line-height: 25px;
                        font-weight: 500;

                        img {
                            margin-right: 5px;
                        }
                    }
                }
                p {
                    color: var(--text-white);
                    font-family: Lato-Regular;
                    font-size: 14px;
                    line-height: 25px;
                    font-weight: 500;
                }
                .get-btn {
                    background-color: var(--white-bg);
                    border-radius: 30px;
                    margin: 10px 0px;
                    width: 188px;
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    align-items: center;
                    cursor: pointer;
                    margin: 0 auto;
                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        .icon-location {
                            margin-right: 6px;
                            width: 10px;
                            height: 14.8px;
                            -webkit-mask-image: url(../images/icon-location.svg);
                            mask-image: url(../images/icon-location.svg);
                            background-color: var(--teal-bg);
                            margin-top: 1px;
                        }
    
                        span {
                            color: var(--text-teal);
                            font-size: 18px;
                            font-weight: bold;
                            font-family: Lato;
                        }
                    }
                }
            }
        }

        .footer-icon {
            ul {
                margin: 0px 0 0px 0;
                padding: 0;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                align-content: stretch;
                justify-content: center;

                li {
                    list-style: none;
                    display: inline-block;
                    margin-right: 17px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer-bottom {
        background-color: #191919;
        padding: 13px 0;

        .copy-right-inner {
            text-align: center;

            em {
                color: var(--text-white);
                font-style: normal;
                display: flex;
                justify-content: center;
                align-items: center;

                p.copy-right-text {
                    margin: 0 0 0 3px;
                    color: var(--text-white);
                    font-family: Lato-Regular;
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.8px;
                    line-height: 16px;
                }
            }


            ul {
                padding: 0;
                margin: 10px 0;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                li {
                    list-style: none;
                    display: inline-flex;

                    a {
                        color: var(--text-white);
                        text-decoration: none;
                        font-family: Lato-Regular;
                        font-weight: 500;
                        font-size: 14px;
                        letter-spacing: 0.8px;
                        line-height: 16px;
                    }

                    &:last-child::before {
                        content: "";
                        margin: 8px 5px 0;
                        width: 0.3rem;
                        height: 0.3rem;
                        border-top-right-radius: 50%;
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                        border-bottom-right-radius: 50%;
                        background-color: var(--white-bg);
                        opacity: 1;
                    }
                }
            }

            .loyalty-text {
                font-size: 12px;
                line-height: 1;
                font-weight: 500;
                color: var(--text-white);
                opacity: 1;
                margin-bottom: 0.4rem;
                font-family: Co Headline;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;

                em {
                    width: 0.8rem;
                    display: inline-block;
                    margin: 0 0.3rem;

                    img {
                        animation: plus-heart 1s infinite;
                        -webkit-animation: plus-heart 1s infinite;
                        vertical-align: middle;
                    }

                    @keyframes plus-heart {
                        0% {
                            transform: scale(1);
                        }

                        50% {
                            transform: scale(1.2);
                        }
                    }
                }

                a {
                    img {
                        width: 97px;
                        margin-left: 6px;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 767px) {
    .footer {
        .footer-top {
            .footer-logo {
                margin-bottom: 12px;
            }

            .footer-icon {
                ul {
                    margin: 6px 0 5px 0;
                }
            }
        }
    }
}