.main-wrapper {
    &#promo-page {
        background-color: #003049;

        .date-inner-title {
            img {
                filter: brightness(0) invert(1);
                position: absolute;
                left: 0;
            }

            p {
                color: #fff;
            }
        }

        .input-with-icon {
            position: relative;

            .input-icon {
                width: 20px;
                display: block;
                position: absolute;
                left: 22px;
                top: 50%;
                transform: translateY(-43%);

                img {
                    width: 100%;
                    display: block;
                }
            }

            .form-control {
                padding: 0 40px 0 47px;
                border-radius: 50px;
                outline: 0;
                height: 50px;

                &:focus {
                    box-shadow: none;
                    border-color: #003049;
                }
            }
        }

        .promo-img {
            padding: 1rem 0 0rem;

            img {
                width: 100%;
            }
        }

        .finished-btn {
            margin: 0 auto;
            width: 35%;
            button {
                width: 100%;
                border: 0;
                background-color: #F77F00;
                border-radius: 50px;
                height: 50px;
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                font-family: Lato-Bold;
                box-shadow: 0px 3px 6px #00000029;
                outline: 0;
            }
        }
        // .footer-btn {
        //     padding: 25px 0;
        //     box-shadow: 0px -5px 6px #00000029;
        // }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {
        &#promo-page {
            .promo-img {
                padding: 7rem 0 2rem;
            }
    
            .finished-btn {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
}