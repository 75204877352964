.home-page {
  background-color: var(--text-black);
  position: relative;

  .banner-img {
    position: relative;

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          span {
            span.react-loading-skeleton {
              z-index: 9;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      height: 523px;
      object-fit: cover;
    }

    .swiper-wrapper .swiper-slide::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: var(--teal-bg);
      opacity: 0.7;
    }
  }

  .date-inner-title {
    position: absolute;
    top: 28%;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .date-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // height: 683px;

      h1 {
        font-size: 50px;
        color: var(--text-white);
        line-height: 63px;
        font-family: Co Headline Bold;
        margin-bottom: 23px;
        text-align: center;
        position: relative;
        z-index: 2;
        max-width: 670px;

        span.react-loading-skeleton {
          border-radius: 10px !important;
        }

        strong {
          font-family: Co Headline Bold;
        }
      }

      button {
        position: relative;
        z-index: 2;

        &.primary-btn {
          width: 20%;
          margin: 0 auto;
          border-radius: 50px;
          height: 50px;
          font-size: 16px;
          line-height: 25px;
          color: var(--text-white);
          font-family: Lato-Bold;
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--text-teal);
          outline: none;
          border: 0;

          &:hover {
            background-color: var(--text-teal);
          }
        }

        span.react-loading-skeleton {
          border-radius: 30px !important;
        }
      }
    }
  }

  .flavours-section {
    .flavour-img {
      margin: 0 auto;
      width: 66%;
      display: flex;
      justify-content: center;
      padding-bottom: 10px;

      img {
        width: 30%;
      }
    }

    .flavour-content {
      text-align: center;

      h2 {
        font-size: 30px;
        color: var(--text-teal);
        font-family: Co Headline Bold;
        line-height: 36px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: var(--text-white);
      }
    }

    .flavour-box {
      margin-bottom: 14px;

      .flavour-img {
        margin: 0 auto;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        padding-bottom: 0px;

        img {
          width: 100%;
          border-radius: 15px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: var(--teal-bg);
          z-index: 1;
          border-radius: 15px;
          opacity: 0.7;
        }

        .flavour-content {
          text-align: center;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          z-index: 2;
          margin: 0 auto;

          h2 {
            font-size: 46px;
            color: var(--text-white);
            font-family: math;
            line-height: 53px;
            margin-bottom: 0px;
            text-transform: math-auto;
            font-weight: 900;
          }
        }
      }
    }

    .tabs {
      >div {
        justify-content: center;
        margin-bottom: 20px;

        >div {
          border-radius: 50px;
          overflow: hidden;
          height: 44px;
          width: 100%;
          background: #f2a00800;
          border: 2px solid #f29f08;
          text-align: center;
          color: rgb(255, 255, 255);
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-family: Lato-Bold;
          margin-right: 11px;

          &:hover {
            background-color: #f29f08;
          }
        }
      }
    }

    .second-flavour {
      .flavour-img {
        &::after {
          content: "";
          background: var(--teal-bg);
        }
      }
    }
  }

  .our-story-section {
    text-align: center;

    h2 {
      font-size: 30px;
      color: var(--text-teal);
      font-family: Co Headline Bold;
      line-height: 36px;
      margin-bottom: 20px;

      span {
        span.react-loading-skeleton {
          margin-bottom: 0px;
        }
      }
    }

    span {
      span.react-loading-skeleton {
        margin-bottom: 20px;
      }
    }

    p {
      color: var(--text-white);

      span {
        span.react-loading-skeleton {
          margin-bottom: 0px;
        }
      }
    }
  }

  .exceptional-section {
    text-align: center;
    padding-bottom: 20px;

    h2 {
      font-size: 30px;
      color: var(--text-teal);
      font-family: Co Headline Bold;
      line-height: 36px;
      margin-bottom: 20px;

      span {
        span.react-loading-skeleton {
          margin-bottom: 0px;
        }
      }
    }

    span {
      span.react-loading-skeleton {
        margin-bottom: 20px;
      }
    }

    p {
      color: var(--text-white);

      span {
        span.react-loading-skeleton {
          margin-bottom: 0px;
        }
      }
    }
  }

  .our-customers-section {
    h2 {
      font-size: 30px;
      color: var(--text-teal);
      font-family: Co Headline Bold;
      line-height: 36px;
      margin-bottom: 34px;
      text-align: center;
    }

    .our-customers-item {
      display: flex;
      overflow: auto;
      margin: 0 -15px;
      padding: 0 15px;

      span {
        width: 95%;
        height: 100%;

        span.react-loading-skeleton {
          margin-right: 20px;
          margin-bottom: 20px;
        }

        &:last-child {
          span.react-loading-skeleton {
            margin-right: 0;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .our-customers-box {
        background-color: var(--white-bg);
        border-radius: 20px;
        width: 100%;
        padding: 40px 20px;
        margin-bottom: 20px;
        text-align: center;
        margin-right: 18px;

        &:last-child {
          margin-right: 0;
        }

        p {
          color: var(--text-black);
          font-size: 15px;
          font-style: italic;
        }

        img {
          width: 162px;
          margin: 0 auto;
        }

        h3 {
          color: var(--text-black);
          margin-bottom: 0;
          font-size: 18px;
          text-transform: math-auto;
          padding: 10px 0 0;
        }
      }
    }
  }

  .new-section {
    padding-bottom: 20px;

    h2 {
      font-size: 30px;
      color: var(--text-teal);
      font-family: Co Headline Bold;
      line-height: 36px;
      margin-bottom: 34px;
      text-align: center;
    }

    .new-section-item {
      display: flex;
      overflow: auto;
      margin: 0 -15px;
      padding: 0 15px;

      &::-webkit-scrollbar {
        display: none;
      }
      span {
        span.react-loading-skeleton {
          margin-right: 12px;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .new-section-box {
        background-color: var(--text-teal);
        border-radius: 22px;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        margin-right: 18px;
        cursor: pointer;

        // &:nth-child(1) {
        //     background-color: var(--yellow-bg);

        //     .new-section-content {
        //         h3 {
        //             color: var(--text-red);
        //         }
        //     }
        // }

        // &:nth-child(4) {
        //     background-color: var(--yellow-bg);

        //     .new-section-content {
        //         h3 {
        //             color: var(--text-red);
        //         }
        //     }
        // }

        // &:nth-child(2) {
        //     background-color: var(--orange-bg);
        // }

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          border-radius: 20px 20px 0 0;
        }

        .new-section-content {
          padding: 20px 20px;

          p {
            color: var(--text-white);
            font-size: 15px;
          }

          h3 {
            font-size: 20px;
            color: var(--text-white);
            font-family: Co Headline Bold;
            line-height: 26px;
            margin-bottom: 10px;
            text-align: center;
          }
        }
      }
    }
  }

  .our-social-section {
    padding-bottom: 20px;

    .our-social-item {
      background-color: var(--white-bg);
      border-radius: 30px;

      h2 {
        font-size: 30px;
        color: var(--text-teal);
        font-family: Co Headline Bold;
        line-height: 36px;
        margin-bottom: 0px;
        text-align: center;
        padding: 34px 0 17px 0;
      }

      ul {
        margin: 0px 0 0px 0;
        padding: 0;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;

        li {
          list-style: none;
          display: inline-block;
          margin-right: 17px;

          &:last-child {
            margin-right: 0;
          }

          &:nth-child(1) {
            a {
              .social_icon {
                width: 16px;
                height: 18px;
                margin: 0 auto;
                -webkit-mask-image: url(../images/tiktok-black.svg);
                mask-image: url(../images/tiktok-black.svg);
                background-color: var(--teal-bg);
                display: block;
              }
            }
          }

          &:nth-child(2) {
            a {
              .social_icon {
                width: 18px;
                height: 18px;
                margin: 0 auto;
                -webkit-mask-image: url(../images/instagram.svg);
                mask-image: url(../images/instagram.svg);
                background-color: var(--teal-bg);
                display: block;
              }
            }
          }

          &:nth-child(3) {
            a {
              .social_icon {
                width: 8px;
                height: 18px;
                margin: 0 auto;
                -webkit-mask-image: url(../images/facebook.svg);
                mask-image: url(../images/facebook.svg);
                background-color: var(--teal-bg);
                display: block;
              }
            }
          }

          &:nth-child(4) {
            a {
              .social_icon {
                width: 20px;
                height: 17px;
                margin: 0 auto;
                -webkit-mask-image: url(../images/Snapchat.svg);
                mask-image: url(../images/Snapchat.svg);
                background-color: var(--teal-bg);
                display: block;
              }
            }
          }
        }
      }

      img.our-social-img {
        width: 100%;
      }
    }
  }

  .whats-new-section {
    padding-bottom: 20px;

    h2 {
      font-size: 30px;
      color: var(--text-teal);
      font-family: Co Headline Bold;
      line-height: 36px;
      margin-bottom: 34px;
      text-align: center;
    }

    .whats-new-item {
      display: flex;
      overflow: auto;
      margin: 0 -15px;
      padding: 0 15px;

      &::-webkit-scrollbar {
        display: none;
      }

      .whats-new-box {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        margin-right: 18px;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          border-radius: 20px;
        }
      }
    }
  }

  .subscribe-section {
    .subscribe-item {
      margin: 0 auto;
      width: 100%;
      // display: flex;
      position: relative;
      // justify-content: center;
      padding-bottom: 0px;

      img {
        width: 100%;
        border-radius: 13px;
        height: 300px;
        object-fit: cover;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgb(26 26 26 / 84%);
        z-index: 1;
        border-radius: 13px;
      }

      .subscribe-content {
        text-align: center;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 2;
        margin: 0 auto;
        flex-direction: column;

        h2 {
          font-size: 30px;
          color: var(--text-teal);
          font-family: Co Headline Bold;
          line-height: 36px;
          margin-bottom: 34px;
        }

        .input-with-icon {
          position: relative;

          .form-control {
            width: 100%;
            height: 50px;
            border-radius: 50px;
            border: 1px solid var(--text-teal);
            padding: 0 103px 0 60px;
            font-size: 16px;
            color: var(--text-white);
            line-height: 22px;
            margin-bottom: 5px;
            outline: none;
            background-color: var(--teal-bg);
            box-shadow: none;

            &:focus {
              color: var(--text-white);
            }
          }

          input:-webkit-autofill {
            -webkit-text-fill-color: var(--text-white) !important;
            -webkit-transition: background-color 9999s ease-in-out 0s;
            transition: background-color 9999s ease-in-out 0s;
          }

          input::placeholder {
            color: var(--text-white);
          }

          .input-icon {
            // width: 13px;
            // height: 9px;
            // margin: 0 auto;
            // -webkit-mask-image: url(../images/mail_icon.svg);
            // mask-image: url(../images/mail_icon.svg);
            // background-color: var(--white-bg);
            position: absolute;
            top: 13px;
            left: 29px;

            img {
              width: 25px;
              height: 13px;
              object-fit: contain;
              filter: brightness(0) invert(1);
            }
          }

          button.btn-primary-subscribe {
            position: absolute;
            top: 9px;
            right: 18px;
            background-color: var(--white-bg);
            color: var(--text-teal);
            border: 0;
            border-radius: 30px;
            font-size: 14px;
            padding: 5px 14px;
            outline: none;
            box-shadow: 0px 3px 6px #00000026;
          }

          p.subscribe-error {
            color: red;
            text-align: left;
          }
        }
      }
    }
  }

  .home-footer {
    background-color: #191919;
    padding: 50px 0;

    .home-footer-img {
      text-align: center;
      padding-bottom: 22px;

      img {
        width: 30%;
        margin: 0 auto;
      }
    }

    .content {
      width: 300px;

      .get-btn {
        background-color: var(--teal-bg);
        border-radius: 30px;
        margin: 10px 0px;
        width: 188px;
        display: flex;
        justify-content: center;
        height: 50px;
        align-items: center;
        cursor: pointer;

        a {
          display: flex;
          align-items: center;

          img {
            margin-right: 6px;
            width: 14px;
            height: 16px;
          }

          span {
            color: var(--text-white);
            font-size: 18px;
            font-weight: bold;
            font-family: Lato;
          }
        }
      }

      h3 {
        color: var(--text-teal);
        font-size: 22px;
        font-family: Co Headline Bold;
        line-height: 30px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      p {
        color: var(--text-white);
        // margin-bottom: 4px;
      }

      span {
        color: var(--text-white);
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-bottom: 5px;

          a {
            text-decoration: none;
            color: var(--text-white);

            img {
              margin-right: 8px;
              filter: invert(23%) sepia(98%) saturate(9762%) hue-rotate(0deg) brightness(80%) contrast(200%);
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .footer-icon {
        ul {
          margin-bottom: 20px;
          margin-top: 10px;

          li {
            display: inline-block;
            margin-right: 15px;

            a {
              img {
                width: 22px;
                height: 22px;
                filter: sepia(0) saturate(6);
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .opening {
        padding: 0 0rem;

        .time {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .home-footer-button {
    background-color: #191919;
    padding: 24px 0;
    position: fixed;
    bottom: 0;
    z-index: 999;
    left: 0;
    right: 0;

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;

      li {
        list-style: none;
        display: flex;
        margin-right: 8px;
        background-color: var(--text-teal);
        border-radius: 6px;
        width: 150px;
        height: 50px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        a {
          color: var(--text-white);
          font-size: 14px;
          text-decoration: none;
        }

        img {
          margin-right: 8px;
          width: 14px;
          height: 14px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-page {
    .banner-img {
      &::after {
        background: rgba(0, 0, 0, 0);
      }

      img {
        height: auto;
        object-fit: contain;
      }
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .our-courses-box {
            .date-inner-title {
              display: none;
            }
          }

          span {
            span.react-loading-skeleton {
              z-index: 9;
              height: 198px ! important;
            }
          }

          &::after {
            background: rgba(0, 0, 0, 0);
          }
        }
      }
    }

    .flavours-section {
      .flavour-img {
        img {
          width: 100%;
        }
      }

      .flavour-box {
        .flavour-img {
          margin-bottom: 26px;
        }

        span {
          span.react-loading-skeleton {
            height: 272px ! important;
          }
        }
      }
    }

    .our-story-section {
      span {
        span.react-loading-skeleton {
          height: 272px ! important;
          margin-bottom: 20px;
        }
      }
    }

    .exceptional-section {
      span {
        span.react-loading-skeleton {
          height: 272px ! important;
          margin-bottom: 20px;
        }
      }
    }

    .our-customers-section {
      .our-customers-item {
        span {
          span.react-loading-skeleton {
            height: 272px ! important;
            margin-bottom: 20px;
          }
        }
      }
    }

    .date-inner-title {
      position: relative;

      .date-title {
        position: relative;
        height: auto;
        padding: 16px 0;

        h1 {
          font-size: 52px;
          line-height: 56px;
          margin-bottom: 5px;
          // max-width: 328px;
          width: 100%;
        }

        button {
          &.primary-btn {
            width: 94%;
          }
        }
      }

      &::after {
        background: rgba(0, 0, 0, 0);
      }
    }

    .our-story-section {
      img {
        width: 66%;
        margin: 0 auto;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .our-customers-section {
      .our-customers-item {
        .our-customers-box {
          max-width: 341px;
          min-width: 341px;
          margin-right: 10px;
        }

        span {

          span.react-loading-skeleton {
            margin-right: 20px;
            height: 100px ! important;
          }

          &:last-child {
            span.react-loading-skeleton {
              margin-right: 0;
            }
          }
        }
      }
    }

    .new-section {
      .new-section-item {
        .new-section-box {
          max-width: 341px;
          min-width: 341px;
          margin-right: 10px;
        }
      }
    }

    .whats-new-section {
      .whats-new-item {
        .whats-new-box {
          max-width: 310px;
          min-width: 310px;
          margin-right: 10px;

          span {
            span.react-loading-skeleton {
              width: 310px !important;
            }
          }
        }
      }
    }

    .our-social-section {
      .our-social-item {
        border-radius: 20px;

        h2 {
          padding: 26px 0 22px 0;
        }

        img.our-social-img {
          border-radius: 0 0 20px 20px;
        }

        ul {
          li {
            a {
              img {
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
      span {
        span.react-loading-skeleton {
          height: 295px ! important;
        }
      }
    }

    .exceptional-section {
      img {
        width: 66%;
        margin: 0 auto;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .subscribe-section {
      .subscribe-item {
        img {
          border-radius: 7px;
          height: auto;
        }

        .subscribe-content {
          .input-with-icon {
            padding: 0 15px;

            .form-control {
              padding: 0 103px 0 45px;
            }

            button.btn-primary-subscribe {
              right: 28px;
              top: 10px;
            }
          }
        }

        &::after {
          border-radius: 7px;
        }
      }
      span {
        span.subscribe-loading-skeleton {
          height: 271px ! important;
        }
      }
    }

    .home-footer {
      padding: 20px 0 35px;

      .home-footer-img {
        img {
          width: 100%;
        }
      }

      .nowrap {
        display: block;
        text-align: center;

        .content {
          width: auto;

          .footer-icon {
            ul {
              margin: 26px 0 26px;

              li {
                a {
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
              }
            }
          }

          .opening {
            padding: 0 5rem;
          }

          .get-btn {
            margin: 20px auto;
          }
        }
      }
    }
  }
}