@import "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css";
@import "https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Cormorant:wght@700&display=swap";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css";
@import "https://unpkg.com//style/react-tabs.css";

@font-face {
  font-family: Co Headline Bold;
  src: url("../../../../public/Co Headline Bold.otf");
}

@font-face {
  font-family: Co Headline;
  src: url("../../../../public/Co Headline.otf");
}

@font-face {
  font-family: Lato-Regular;
  src: url("../../../../public/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato-Bold;
  src: url("../../../../public/Lato-Bold.ttf");
}

@import "signup.scss";
@import "header.scss";
@import "footer.scss";
@import "verification.scss";
@import "ordering-menu.scss";
@import "check-out.scss";
@import "cart.scss";
@import "product-details.scss";
@import "promo-code.scss";
@import "account.scss";
@import "payment.scss";
@import "register.scss";
@import "home.scss";
@import "your-orders.scss";
@import "_variables_.scss";
@import "invite-friends.scss";

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  
    .loader {
      position: fixed;
      margin: 0 auto;
      transform: translate(0%, 0%);
      width: 100%;
      height: 100%;

      &::before {
        content: "";
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: radial-gradient(farthest-side, #cccaca 94%, #0000) top/4px 4px no-repeat,
          conic-gradient(#0000 30%, #cccaca);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
        animation: spinner-ub 0.8s infinite linear;
        position: absolute;
      }
    }
  


  .skeleton-container {
    width: 43%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Skeleton profile section */
  .skeleton-profile {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }

  .skeleton-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #e0e0e0;
    position: relative;
    overflow: hidden;
  }

  .skeleton-info {
    flex-grow: 1;
  }

  .skeleton-line {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .skeleton-line.short {
    width: 60%;
  }

  .skeleton-content .skeleton-line {
    margin-bottom: 15px;
  }

  .skeleton-circle::before,
  .skeleton-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes spinner-ub {
    100% {
      transform: rotate(1turn);
    }
  }

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-container.hide {
  opacity: 0; 
  visibility: hidden; 
}

.loader-container.show {
  opacity: 1; 
  visibility: visible;
}

.skeleton-box {
  width: 200px;
  height: 40px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, rgb(146, 146, 146), #f0f0f0, rgb(132, 132, 132));
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
}

/* Dark Mode Skeleton */
.skeleton-box.dark-mode {
  background-color: #333;
  background-image: linear-gradient(90deg, #444, #555, #444);
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

svg circle {
  transform-origin: 50% 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// .skeleton-box {
//   width: 200px;
//   height: 40px;
//   background-color: #e0e0e0;
//   background-image: linear-gradient(90deg, rgb(146, 146, 146), #f0f0f0, rgb(132, 132, 132));
//   border-radius: 4px;
//   animation: shimmer 1.5s infinite linear;
// }

// /* Dark Mode Skeleton */
// .skeleton-box.dark-mode {
//   background-color: #333;
//   background-image: linear-gradient(90deg, #444, #555, #444);
// }

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

svg circle {
  transform-origin: 50% 50%;
}

@keyframes spinner-ub {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

.skeleton-box {
  width: 200px;
  height: 40px;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, rgb(146, 146, 146), #f0f0f0, rgb(132, 132, 132));
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
}

/* Dark Mode Skeleton */
.skeleton-box.dark-mode {
  background-color: #333;
  background-image: linear-gradient(90deg, #444, #555, #444);
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

// :root {
//   // Body
//   --body-bg: #fff;

//   // Background Colors

//   --teal-bg: #C0382B;
//   --white-bg: #ffffff;
//   --black-bg: #000;
//   --lightgray-bg: #e8e8e8;
//   --gray-bg: #e8e8e8;
//   --dimgray-bg: #6b6b6b;
//   --inner-green-bg: rgb(232, 75, 60);
//   --gray-background-color: #f2f2f2;
//   --purple-bg: #FF0000;
//   --sky-blue-bg: #718cb5;
//   --pink-bg: #e75d85;
//   --gray-bg1: #ccc;
//   --sky-blue-inner-bg: #a0c3bf;


//   // Border Colors
//   --border-color: #20827b;
//   --green-border-color: #16a592;
//   --border-white: #fff;
//   --border-light-gray: #ccc;
//   --border-purple: #FF0000;
//   --border-pink: #e75d85;
//   --border-offwhite: #dee2e6;

//   // Typography
//   --font-family-sans-serif: 'Lato', sans-serif;
//   --font-family: Open Sans, sans-serif;
//   --cormorant-font-family: Cormorant, serif;
//   --font-weight: 500;
//   --font-size-base: 0.9rem;
//   --line-height-base: 1.6;

//   // Button colors
//   --btn-teal-bg: #20827b;
//   --btn-white-bg: #ffffff;

//   // Text Colors
//   --text-teal: #C0382B;
//   --text-white: #ffffff;
//   --text-black: #000;
//   --primary-color: #20827b;
//   --gary-text-color: #333;
//   --blue-text-color: #0071bc;
//   --purple-text-color: #FF0000;
//   --text-pink: #e75d85;
//   --text-dark-gray: #4d4d4d
// }