.main-wrapper {
  background-color: #faf8f8;
  padding: 24px 0;

  .date-inner-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    a {
      .back_icon {
        position: absolute;
        left: 0px;
        top: 12px;
        width: 19.5px;
        height: 15px;
        -webkit-mask-image: url(../images/back-icon.svg);
        mask-image: url(../images/back-icon.svg);
        background-color: var(--teal-bg);
      }
    }

    p {
      font-size: 23px;
      color: var(--text-teal);
      line-height: 30px;
      font-family: Co Headline Bold;
      margin-bottom: 9px;
    }
  }

  .react-tabs {
    text-align: -webkit-center;

    .tabs {
      display: flex;
      margin-bottom: 30px;
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0px 1px 3px #00000029;
      padding: 5px;
      justify-content: space-between;
      width: 360px;

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  #deliver-page {
    .large-box {
      .section-inner {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
        margin-bottom: 30px;
        // padding-bottom: 15px;

        &:last-child {
          margin-bottom: 19px;
        }

        .box {
          display: flex;
          align-items: self-start;
          justify-content: flex-start;
          margin-bottom: 0px;
          width: 100%;

          .icon {
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 8px;
            }
          }

          .content {
            flex-wrap: nowrap;
            display: inline-flex;
            width: calc(100% - 80px);
            margin-left: 1px;
            flex-direction: column;

            h4 {
              font-size: 16px;
              margin-bottom: 2px;
              // color: var(--text-teal);
              font-family: Lato-Bold;
              color: #003049;
            }

            p {
              font-size: 14px;
              color: #767676;
            }
          }
        }

        .edit-btn {
          border-radius: 30px;
          background-color: #fff;
          // color: var(--text-teal);
          color: #003049;
          font-size: 13px;
          font-family: Lato-Bold;
          border: 0;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
          width: 55px;
          height: 100%;
          outline: none;
          margin-bottom: 20px;

          .btn-primary {
            background-color: transparent;
            color: #003049;
            font-size: 13px;
            font-family: Lato-Bold;
            border: 0;
            box-shadow: none;
            outline: none;
            width: 55px;
            text-align: center;
            padding: 4px 2px;
          }

          &.options {
            width: 100%;
            border-radius: 10px;

            .btn-primary {
              padding: 10px 10px;
              text-align: left;
              font-size: 15px;
              width: 100%;

              img {
                margin-right: 9px;
                vertical-align: baseline;
              }
            }
          }

          .options-dropdown {
            padding: 0 10px 10px;

            label {
              display: flex;
              align-items: center;
              padding: 8px 6px;
              margin-bottom: 8px;
              border-radius: 5px;
              font-size: 13px;
              color: var(--text-teal);
              cursor: pointer;
              transition: background-color 0.3s ease;
              text-align: left;

              // &:hover {
              //   background-color: var(--teal-bg);
              //   color: var(--text-white);
              // }
              &.active {
                background-color: var(--teal-bg);
                color: var(--text-white);
                box-shadow: 0px 1px 3px #00000029;
              }

              input {
                margin-right: 5px;
              }

              input[type="radio"] {
                appearance: none;
                -webkit-appearance: none;
                position: relative;
                width: 15px;
                height: 15px;
                background-color: var(--white-bg);
                border: 2px solid var(--teal-bg);
                border-radius: 50%;
                cursor: pointer;
                outline: none;
                margin-right: 10px;
                display: inline-block;
                transition: background-color 0.3s, border-color 0.3s;
              }

              &.active input[type="radio"] {
                background-color: var(--teal-bg);
                border-color: var(--white-bg);
              }

              &.active input[type="radio"]::before {
                content: "";
                position: absolute;
                top: 3px;
                left: 3px;
                width: 6px;
                height: 6px;
                background-color: var(--white-bg);
                border-radius: 50%;
              }
            }
          }
        }
      }

      .address-input {
        display: flex;
        align-items: baseline;
        align-content: stretch;
        flex-wrap: nowrap;
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding-top: 0px;
        margin-top: -9px;
        border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);

        &:nth-child(3) {
          padding-top: 5px;
          margin-bottom: 30px;
        }

        p {
          margin-bottom: 0;
          margin-left: 10px;
        }
      }
    }

    .location-img {
      img {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &.pick-up {
      .delivery-text {
        display: flex;
        justify-content: space-between;

        .delivery-box {
          width: 48%;
          &:nth-child(2) {
            // display: none;
          }

          &.disabled {
            width: 100%;
          }

          .box {
            .content {
              width: calc(100% - 60px);
            }
          }

          &:last-child {
            .box {
              // .icon {
              //   img {
              //     filter: brightness(0) invert(0.1);
              //   }
              // }

              .content {
                // h4 {
                //   color: #767676;
                // }

                p {
                  color: #767676;
                }
              }
            }
          }
        }
      }

      .Pickup-estimate {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        h2 {
          font-size: 14px;
          line-height: 30px;
          color: #003049;
          font-family: Lato-Bold;
          margin-bottom: 0;

          img {
            margin-right: 3px;
            width: 14px;
            vertical-align: text-top;
            margin-top: 1px;
          }
        }

        h5 {
          font-size: 11px;
          line-height: 25px;
          color: #003049;
          font-family: Lato-Bold;
          margin-bottom: 0;
        }
      }
    }

    .delivery-text {
      border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
      padding-bottom: 15px;

      h2 {
        font-size: 20px;
        color: var(--text-teal);
        line-height: 30px;
        font-family: Co Headline Bold;
        margin-bottom: 20px;
      }

      .delivery-box {
        // background-color: #f77f00;
        border-radius: 15px;
        margin-bottom: 15px;
        box-shadow: 0px 1px 3px #00000029;
        padding: 20px;
        &:nth-child(3) {
          // display: none;
        }

        &.active {
          background-color: var(--teal-bg);

          .box {
            .icon {
              img {
                filter: brightness(0) invert(1);
              }
            }

            .content {
              h4 {
                color: #fff !important;
              }

              p {
                color: #fff !important;
              }
            }
          }
        }

        &:last-child {
          // background-color: #fff;
        }

        .box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0px;
          width: 100%;

          .icon {
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 15px;
              filter: brightness(0) invert(0.1);
            }
          }

          .content {
            flex-wrap: nowrap;
            display: inline-flex;
            width: calc(100% - 80px);
            margin-left: 1px;
            flex-direction: column;

            h4 {
              font-size: 16px;
              margin-bottom: 2px;
              color: #003049;
              font-family: Lato-Bold;
            }

            p {
              font-size: 14px;
              color: #767676;
              margin-bottom: 0;
            }
          }
        }

        .select-btn {
          .btn-primary {
            border-radius: 30px;
            background-color: #fff;
            color: #003049;
            font-size: 11px;
            font-family: Lato-Bold;
            border: 0;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
            width: 85px;
            height: 25px;
          }
        }
      }

      .delivery-box:nth-child(3) {
        .box {
          .icon {
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 15px;
            }
          }

          .content {
            flex-wrap: nowrap;
            display: inline-flex;
            width: calc(100% - 80px);
            margin-left: 1px;
            flex-direction: column;

            h4 {
              font-size: 14px;
              margin-bottom: 2px;
              color: #003049;
              font-family: Lato-Bold;
            }

            p {
              font-size: 12px;
              color: #767676;
            }
          }
        }
      }
    }
  }

  &#check-out-page {
    padding: 24px 0 0;

    .order-summary {
      margin-top: 24px;

      .order-summary-text {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 20px;
          color: var(--text-teal);
          line-height: 30px;
          font-family: Co Headline Bold;
          margin-bottom: 7px;
        }

        .add-btn {
          .btn-primary {
            border-radius: 30px;
            background-color: var(--white-bg);
            color: var(--text-teal);
            font-size: 13px;
            font-family: Lato-Bold;
            border: 0;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
            width: 103px;
            height: 35px;
          }
        }
      }

      .order-summary-inner {
        margin-top: 20px;

        .order-summary-text {
          display: block;
          border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
          margin-bottom: 20px;

          &.add-items {
            margin-left: 30px;

            &:last-child {
              border-bottom: 0;
            }

            span {
              width: 20px;
              height: 20px;
              font-size: 13px;
              line-height: 19px;
            }
          }

          span {
            background-color: var(--teal-bg);
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: var(--text-white);
            margin-right: 10px;
            line-height: 23px;
          }

          .content {
            display: flex;
            justify-content: space-between;
            margin-left: 20px;

            h6 {
              font-size: 15px;
              // color: var(--text-teal);
              color: #003049;
              line-height: 22px;
              font-family: Co Headline Bold;
              margin-bottom: 3px;
            }

            .title-number {
              font-size: 15px;
              // color: var(--text-teal);
              color: #003049;
              line-height: 22px;
              font-family: Co Headline Bold;
              margin-bottom: 3px;
            }
          }

          p {
            font-size: 13px;
            line-height: 16px;
            color: #767676;
            margin-bottom: 20px;
            margin-left: 35px;
          }
        }
      }

      .add-promo {
        .text {
          h2 {
            font-size: 14px;
            color: #003049;
            line-height: 22px;
            font-family: Co Headline Bold;
            margin-bottom: 12px;
          }
        }

        .add-inner-promo {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 19px;
          }

          .box {
            display: flex;
            align-items: self-start;
            justify-content: flex-start;
            margin-bottom: 0px;
            width: 100%;

            .icon {
              border-radius: 5px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              img {
                margin-right: 8px;
              }
            }

            .content {
              flex-wrap: nowrap;
              display: inline-flex;
              width: calc(100% - 80px);
              margin-left: 1px;
              flex-direction: column;

              h4 {
                font-size: 14px;
                margin-bottom: 2px;
                color: #003049;
                font-family: Lato-Bold;
              }

              p {
                font-size: 12px;
                color: #767676;
              }
            }
          }

          .add-btn {
            .btn-primary {
              border-radius: 30px;
              background-color: #fff;
              color: #003049;
              font-size: 11px;
              font-family: Lato-Bold;
              border: 0;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
              width: 50px;
              height: 25px;
            }
          }
        }
      }

      .subtotal-box {
        border-bottom: 1px dotted hsl(0deg 0% 10.38% / 97%);
        padding-bottom: 20px;

        .subtotal-box-content {
          display: flex;
          justify-content: space-between;

          h4 {
            font-size: 16px;
            font-family: Lato-Bold;
            line-height: 30px;
            margin-bottom: 0;
            color: var(--dimgray-bg);

            img {
              vertical-align: text-bottom;
              cursor: pointer;
            }
          }
        }
      }

      .total-content {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
        margin: 15px 0;
        padding-bottom: 20px;

        h4 {
          font-size: 19px;
          line-height: 30px;
          color: var(--text-teal);
          // font-family: Lato-Bold;
          font-weight: 700;
          margin-bottom: 0px;
        }
      }

      .payment-method {
        .content {
          h2 {
            font-size: 14px;
            color: #003049;
            line-height: 22px;
            font-family: Co Headline Bold;
            margin-bottom: 12px;
          }
        }

        .payment-inner-method {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid hsl(0deg 0% 10.38% / 15%);
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 19px;
          }

          .box {
            display: flex;
            align-items: self-start;
            justify-content: flex-start;
            margin-bottom: 0px;
            width: 100%;

            .icon {
              border-radius: 5px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              img {
                margin-right: 8px;
              }
            }

            .content {
              flex-wrap: nowrap;
              display: inline-flex;
              width: calc(100% - 80px);
              margin-left: 1px;
              flex-direction: column;

              h4 {
                font-size: 14px;
                margin-bottom: 2px;
                color: #003049;
                font-family: Lato-Bold;
              }

              p {
                font-size: 12px;
                color: #767676;
              }
            }
          }

          .edit-btn {
            .btn-primary {
              border-radius: 30px;
              background-color: #fff;
              color: #003049;
              font-size: 11px;
              font-family: Lato-Bold;
              border: 0;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
              width: 50px;
              height: 25px;
            }
          }
        }
      }

      .allergies-text {
        h2 {
          font-size: 14px;
          color: #003049;
          line-height: 22px;
          font-family: Co Headline Bold;
          margin-bottom: 8px;
        }

        p {
          font-size: 12px;
          line-height: 18px;
          color: #767676;
        }
      }
    }

    .continue-btn {
      background-color: var(--teal-bg);
      padding: 25px 0;

      .continue-payment-btn {
        width: 30%;
        margin: 0 auto;

        .btn-primary {
          background-color: var(--white-bg);
          border-radius: 30px;
          box-shadow: 0px 1px 3px #00000029;
          color: var(--text-teal);
          font-weight: 700;
          border: 0;
          font-size: 16px;
          line-height: 25px;
          height: 50px;
          width: 100%;
          outline: 0;
        }
      }
    }
  }
}

.sumotip-modal {
  .modal-dialog {
    margin: 0rem auto;
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    width: 481px;
    justify-content: center;
    align-items: center;

    .modal-content {
      border-radius: 25px;
      border: 0;

      .modal-header {
        border-bottom: 0;
        justify-content: flex-end;

        button {
          &.btn-close {
            background-image: url(../images/close-icon.svg);
            border: 0;
            height: 12px;
            width: 12px;
            outline: 0;
          }
        }
      }

      .modal-body {
        .sumotip-popup-modal-inner {
          .sumotip-popup {
            text-align: center;

            h2 {
              font-size: 21px;
              line-height: 30px;
              font-family: Co Headline Bold;
              margin-bottom: 9px;
              color: #f77f00;
            }

            p {
              font-size: 14px;
              color: #003049;
              line-height: 24px;
              font-family: Lato-Bold;
              margin-bottom: 20px;
            }
          }

          .donation-summary {
            .donation-summary-section-amount {
              align-items: baseline;
              display: flex;
              justify-content: space-between;
              padding: 0rem 0;
              position: relative;

              .cutom-radio {
                border: 2px solid #f77f00;
                border-radius: 45px;
                color: #f77f00;
                display: inline-block;
                height: 50px;
                line-height: 45px;
                margin-bottom: 1rem;
                margin-right: 10px;
                margin-top: 0rem;
                text-align: center;
                width: 260px;

                label {
                  input {
                    display: none;
                  }
                }

                &.active {
                  background-color: #f77f00;
                  color: #fff;
                }
              }
            }
          }

          .gotit-btn {
            button {
              &.btn-primary {
                border: 0;
                border-radius: 30px;
                background-color: #f77f00;
                color: #fff;
                font-size: 16px;
                line-height: 25px;
                padding: 2px 10px;
                font-family: Lato-Bold;
                width: 100%;
                height: 50px;
                margin-top: 20px;
                margin-bottom: 20px;
                outline: 0;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
              }
            }
          }
        }
      }
    }
  }
}

.Schedule-delivery-modal {
  .modal-dialog {
    margin: 0rem auto;
    position: absolute;
    top: 0px;
    // bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    width: 481px;
    justify-content: center;
    align-items: center;

    .modal-content {
      border-radius: 25px 25px;
      border: 0;
      padding: 1rem 1rem;
      background-color: #faf8f8;
      margin: 4rem auto;
      height: 713px;
      overflow: hidden;

      .modal-header {
        border-bottom: 0;
        padding: 10px 0px;

        button {
          background-color: transparent;

          &.btn-close {
            // background-image: url(../images/close-icon.svg);
            -webkit-mask-image: url(../images/close-icon.svg);
            mask-image: url(../images/close-icon.svg);
            border: 0;
            height: 12px;
            width: 12px;
            background-size: 18px;
            outline: 0;
            padding: 0;
            background-color: var(--teal-bg);
          }
        }
      }

      .modal-body {
        padding: 0;

        .Schedule-delivery-popup-modal-inner {
          .select-item {
            overflow-x: auto;
            height: 100%;
            position: absolute;
            top: 155px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .Schedule-delivery-popup {
            h2 {
              font-size: 21px;
              line-height: 30px;
              font-family: Co Headline Bold;
              margin-bottom: 9px;
              color: var(--text-teal);
            }
          }

          .sun-box {
            overflow: auto;
            margin: 0 -16px;
            padding: 0 0px 0 0px;

            &::-webkit-scrollbar {
              display: none;
            }

            ul {
              list-style: none;
              padding: 19px 0 0;
              margin: 0;
              display: flex;
              // width: 755px;

              li {
                background-color: var(--white-bg);
                width: 100px;
                height: 75px;
                border-radius: 15px;
                box-shadow: 0px 1px 3px #00000029;
                padding: 16px 14px;
                margin-right: 8px;
                font-size: 14px;
                color: #003049;
                font-family: Lato-Bold;
                line-height: 23px;
                margin-bottom: 5px;
                max-width: 100px;
                min-width: 100px;
                cursor: pointer;

                &:last-child {
                  margin-right: 0;
                }

                &.active {
                  background-color: var(--teal-bg);
                  color: var(--text-white);
                  font-weight: 500;

                  p {
                    color: var(--text-white);
                  }
                }

                p {
                  font-size: 12px;
                  color: #767676;
                  font-family: Lato-Regular;
                  margin-bottom: 0;
                }
              }
            }
          }

          .swiper {
            width: 100%;
            height: 100%;

            .swiper-wrapper {
              max-width: 796px;
              min-width: 796px;
              padding: 20px 0;
            }

            .swiper-slide {
              text-align: left;
              font-size: 18px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: #fff;
              border-radius: 15px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.161);
              color: #003049;
              cursor: pointer;
              font-family: Lato-Bold;
              font-size: 14px;
              height: 75px;
              line-height: 23px;
              margin-bottom: 5px;
              margin-right: 8px;
              padding: 16px 14px;
              width: 100px;

              &:first-child {
                margin-left: 15px;
              }

              &:last-child {
                margin-right: 0px !important;
                width: auto !important;
                padding: 0 31px 0 15px !important;
              }

              &.active {
                background-color: var(--teal-bg);
                color: var(--text-white);
                font-weight: 500;

                p {
                  color: var(--text-white);
                }
              }

              p {
                color: #767676;
                font-family: Lato-Regular;
                font-size: 12px;
                margin-bottom: 0;
              }
            }
          }

          .select-item {
            .cutom-radio {
              border-radius: 0px;
              color: #003049;
              display: inline-block;
              height: 50px;
              line-height: 33px;
              margin-bottom: 1rem;
              /* margin-right: 0px; */
              /* margin-top: 0rem; */
              text-align: left;
              font-family: Lato-Bold;
              width: 100%;
              border-bottom: 1px solid #cccccc7d;

              &:last-child {
                border-bottom: 0;
              }

              label {
                display: flex;
                width: 100%;

                input {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.fees-modal {
  cursor: pointer;
  padding-left: 0 !important;

  .modal-dialog {
    margin: 5rem auto;
    position: absolute;
    top: 0px;
    display: flex;
    left: 0;
    right: 0;
    width: 481px;
    justify-content: center;
    align-items: center;
    position: relative;

    .modal-content {
      border-radius: 25px 25px;
      border: 0;
      padding: 1rem 1rem;
      background-color: #faf8f8;

      .modal-header {
        border-bottom: 0;
        padding: 10px 0px;

        button {
          background-color: transparent;

          &.btn-close {
            // background-image: url(../images/close-icon.svg);
            -webkit-mask-image: url(../images/close-icon.svg);
            mask-image: url(../images/close-icon.svg);
            border: 0;
            height: 12px;
            width: 12px;
            background-color: var(--teal-bg);
            background-size: 18px;
            outline: 0;
            padding: 0;
          }
        }
      }

      .modal-body {
        padding-top: 0;

        .fees-title {
          h4 {
            font-size: 21px;
            line-height: 30px;
            font-family: Co Headline Bold;
            margin-bottom: 30px;
            color: var(--text-teal);
            text-align: center;
          }

          h2 {
            font-size: 14px;
            line-height: 22px;
            font-family: Co Headline Bold;
            margin-bottom: 0px;
            color: #003049;
            text-align: center;
          }

          p {
            font-size: 14px;
            line-height: 24px;
            font-family: Lato-Bold;
            margin-bottom: 30px;
            color: #003049;
            text-align: center;
            margin-top: 10px;
          }

          .got-it-btn {
            .btn-primary {
              background-color: var(--teal-bg);
              border-radius: 30px;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
              color: #fff;
              font-weight: 600;
              border: 0;
              font-size: 16px;
              line-height: 25px;
              height: 50px;
              width: 100%;
              outline: 0;
              margin-top: 30px;
            }
          }
        }

        .sumotip-modal {
          h4 {
            margin-bottom: 18px;
          }

          h2 {
            margin-bottom: 0px;
          }

          p {
            margin-bottom: 18px;
            margin-top: 0px;
          }

          .custom-radio {
            display: flex;
            justify-content: space-between;

            .custom-radio-box {
              // background-color: #f77f00;
              border-radius: 30px;
              height: 45px;
              border: 2px solid var(--teal-bg);
              width: 90px;
              text-align: center;
              // cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              &.active {
                background-color: var(--teal-bg);

                label {
                  color: #fff;
                }
              }

              span.currency {
                position: absolute;
                left: 21px;
                filter: brightness(0) invert(1);
                font-weight: 500;
                font-size: 14px;
                top: 11 px;
              }

              input {
                &.form-control {
                  border-radius: 50px;
                  background-color: var(--teal-bg);
                  border: 0;
                  text-align: center;
                  color: var(--text-white);
                  height: 45px;
                  width: 90px;
                  box-shadow: none;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 0 28px;
                }
              }

              label {
                color: var(--text-teal);
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 500;
                width: 100%;
                height: 45px;
                // cursor: pointer;
                line-height: 45px;

                input {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-wrapper {
    .react-tabs {
      text-align: center;

      .tabs {
        width: 100%;
        margin-bottom: 17px;
      }
    }

    #deliver-page {
      .large-box {
        padding-top: 15px;

        .section-inner {
          .box {
            .content {
              h4 {
                line-height: 11px;
                margin-bottom: 5px;
              }
            }
          }

          .edit-btn {
            &.options {
              width: 100%;
              border-radius: 10px;
            }

            .options-dropdown {
              label {
                justify-content: space-between;
                flex-direction: row-reverse;
                padding: 8px 20px 8px 25px;

                input[type="radio"] {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      .location-img {
        img {
          width: 100%;
          margin-bottom: 4px;
        }
      }

      &.pick-up {
        .delivery-text {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .delivery-box {
            width: 100%;

            .box {
              .content {
                width: calc(100% - 60px);
              }
            }

            &.active {
              .box {
                .icon {
                  img {
                    filter: brightness(0) invert(1);
                  }
                }
              }
            }

            &:last-child {
              .box {
                // .icon {
                //   img {
                //     filter: invert(97%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(13%) contrast(14%);
                //   }
                // }

                .content {
                  // h4 {
                  //   color: #767676;
                  // }

                  p {
                    color: #767676;
                  }
                }
              }
            }
          }
        }

        .Pickup-estimate {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          h2 {
            font-size: 16px;
            line-height: 30px;
            color: #003049;
            font-family: Lato-Bold;
            margin-bottom: 0;

            img {
              margin-right: 3px;
              width: 18px;
              vertical-align: text-top;
              margin-top: 1px;
            }
          }

          h5 {
            font-size: 14px;
            line-height: 25px;
            color: #003049;
            font-family: Lato-Bold;
            margin-bottom: 0;
          }
        }
      }
    }

    &#check-out-page {
      .continue-btn {
        .continue-payment-btn {
          width: 100%;
        }
      }
    }
  }

  .fees-modal {
    .modal-dialog {
      width: 96%;
      bottom: 0;

      .modal-content {
        .modal-body {
          .sumotip-modal {
            .custom-radio {
              -moz-columns: 147px 4;
              columns: 147px 4;
              display: block;

              .custom-radio-box {
                width: 100%;
                margin-bottom: 15px;

                span.currency {
                  position: absolute;
                  left: 47px;
                }

                input {
                  &.form-control {
                    width: 100%;
                    padding: 0 55px;
                  }
                }
              }
            }

            .got-it-btn {
              .btn-primary {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }

  .sumotip-modal {
    .modal-dialog {
      width: 95%;
    }
  }

  .Schedule-delivery-modal {
    .modal-dialog {
      width: 96%;
      position: absolute;
      // bottom: 0;
      // top: 0;
      margin: 0rem auto;

      .modal-content {
        margin: 3rem auto;

        .modal-body {
          .Schedule-delivery-popup-modal-inner {
            .sun-box {
              padding: 0 3px 0 15px;
            }

            #style-3 {
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 460px) {
  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    input.form-control {
    padding: 0 73px 0 76px;
  }

  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    span.currency {
    left: 67px;
    top: 11px;
  }
}

@media only screen and (max-device-width: 430px) {
  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    input.form-control {
    padding: 0 67px;
  }

  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    span.currency {
    left: 61px;
  }
}

@media only screen and (max-device-width: 390px) {
  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    input.form-control {
    padding: 0 58px 0 60px;
  }

  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    span.currency {
    left: 48px;
  }
}

@media only screen and (max-device-width: 375px) {
  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    input.form-control {
    padding: 0 130px;
  }

  .fees-modal
    .modal-dialog
    .modal-content
    .modal-body
    .sumotip-modal
    .custom-radio
    .custom-radio-box
    span.currency {
    left: 119px;
  }
}
