.invite-friends-page {
    padding: 24px 0;
    background-color: #FAF8F8;

    .date-inner-title {
        justify-content: space-between;
        display: flex;
        align-items: center;

        P {
            margin-bottom: 0;
            font-size: 21px;
            color: #003049;
            line-height: 30px;
            font-family: Co Headline Bold;
        }

        .shoping-img {
            width: 22px;
            height: 22px;
        }
    }

    .share-gift-img {
        text-align: center;
        padding-bottom: 15px;
        padding-top: 20px;

        img {
            width: 26%;
            margin: 0 auto;
        }
    }

    .Copied-btn {
        position: relative;
        width: 50%;
        margin: 24px auto;

        .form-control {
            border-radius: 50px;
            height: 50px;
            border: 0;
            box-shadow: 0px 3px 6px #00000029;
            padding: 0.5rem 20px 0.5rem 30px;
        }

        button {
            &.btn.Copied {
                position: absolute;
                top: 10px;
                right: 20px;
                background: #F77F00;
                border-radius: 50px;
                display: flex;
                align-items: center;
                bottom: 0;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                color: #fff;
                height: 30px;
                font-size: 10px;
            }
        }
    }

    .invite-friends-btn {
        width: 50%;
        margin: 0 auto;
        .btn-primary {
            background-color: #F77F00;
            border-radius: 30px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
            color: #fff;
            font-weight: 700;
            border: 0;
            font-size: 16px;
            line-height: 25px;
            height: 50px;
            width: 100%;
            outline: 0;
        }
    }

    .share-gift-content {
        text-align: center;

        h2 {
            font-size: 21px;
            color: #003049;
            line-height: 30px;
            font-family: Co Headline Bold;
            margin-bottom: 9px;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            font-family: Lato-Regular;
            color: #767676;
            margin-bottom: 6px;
            display: grid;

            a {
                text-decoration: none;
                color: #003049;
                font-family: Lato-Bold;
                border-bottom: 1px solid #003049;
                width: 62px;
                margin: 0 auto;
            }
        }

        .share-container {

            .share-top-pro-section {
                display: flex;
                justify-content: space-between;
                padding-top: 24px;
                width: 40%;
                margin: 0 auto;

                .share-top-pro {
                    background-color: #fff;
                    border-radius: 25px;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                    width: 100%;
                    margin-right: 15px;
                    padding: 41px 23px;
                    border: dotted 2px #F77F00;
                    border-style: dashed;
                    height: 172px;
                    display: grid;
                    align-content: center;

                    span {
                        img {
                            width: 43px;
                            height: 32px;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    h5 {
                        font-size: 16px;
                        color: #F77F00;
                        line-height: 30px;
                        font-family: Co Headline Bold;
                        margin-bottom: 1px;
                    }

                    p {
                        font-size: 10px;
                    }
                }

                .share-top-pro:nth-child(2) {
                    border: dotted 2px #FCBF49;
                    border-style: dashed;

                    span {
                        img {
                            width: 37px;
                            height: 39px;
                        }
                    }

                    h5 {
                        color: #FCBF49;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .invite-friends-page {
        .date-inner-title {
            .shoping-img {
                width: 15px;
                height: 14px;
            }
        }

        .share-gift-img {
            img {
                width: 186px;
            }
        }

        .share-gift-content {

            .share-container {
                .share-top-pro-section {
                    width: 100%;
                }
            }
        }

        .Copied-btn {
            width: 100%;
        }
        .invite-friends-btn {
            width: 100%;
        }
    }

}