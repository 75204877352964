.header-area {
  background-color: var(--teal-bg);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1050;
  padding: 17px 0;

  .header-left {
    text-align: left;

    a {
      img {
        width: 42px;
      }
    }
  }

  .header-right {
    display: flex;
    text-align: end;
    justify-content: flex-end;
    align-items: center;

    .cart-icon {
      margin-right: 20px;
      position: relative;

      img {
        width: 22px;
      }

      span {
        background-color: #f77f00;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        /* margin-right: 6px; */
        line-height: 15px;
        position: absolute;
        font-size: 10px;
        right: -8px;
      }
    }
  }

  .flex-wrap-nowrap {
    flex-wrap: nowrap;
    align-items: center;

    .menu-button {
      outline: 0;
      border: 0;
      background: transparent;
      padding-right: 0;

      span {
        background-color: var(--text-white);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        display: block;
        height: 4px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -webkit-transition: all 0s;
        -moz-transition: all 0.5s;
        width: 1.8rem;
      }

      span+span {
        margin-top: 0.32rem;
      }
    }

    .menu {
      display: none;
      flex-direction: column;
      transition: all 0.5s;
      min-height: 100vh;
      position: fixed;
      top: 0;
      right: -100%;
      z-index: 1;
      transition: 0.5s;
    }

    .menu.open {
      display: block;
      position: fixed;
      right: 0;
      top: 2px;
      background-color: var(--teal-bg);
      width: 450px;
      // height: calc(100vh - 60px);
      transition: all 0.5s;
      overflow: hidden;
      border-radius: 25px 0 0 25px;
      box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1607843137);

      .hide-desk {
        padding: 30px 17px;

        .logo {
          text-align: center;

          img {
            width: 100px;
            margin-bottom: 5px;
          }

          h2 {
            font-size: 16px;
            line-height: 30px;
            color: var(--text-white);
            font-family: Lato-Bold;
            margin-bottom: 3px;
          }

          p {
            color: var(--text-white);
            font-size: 12px;
            cursor: pointer;
          }
        }

        .meno-menu {
          .mobo-menu {
            text-align: left;

            li {
              margin: 0px;
              border-bottom: 1px solid #e0e0e075;
              padding: 25px 0;

              &:last-child {
                border-bottom: 0;
              }

              a {
                color: var(--text-white);
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                font-family: Lato-Bold;

                p {
                  margin-bottom: 0;
                  color: #a5a5a5;
                  font-size: 12px;
                  margin-left: 22px;
                }

                img {
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .signup-btn {
          margin-top: 30px;

          button {
            &.btn-primary {
              background-color: var(--white-bg);
              width: 100%;
              height: 50px;
              border-radius: 50px;
              color: var(--teal-bg);
              box-shadow: 0px 3px 6px #00000029;
              border: 0;
              outline: 0;
              font-size: 16px;
              line-height: 25px;
              font-family: Lato-Bold;
              margin-bottom: 15px;

              &.login-btn {
                background-color: var(--teal-bg);
                color: var(--text-white);
                border: 2px solid var(--text-white);
              }

              &.Sign-out-btn {
                background-color: var(--teal-bg);
                color: var(--text-white);
                border: 2px solid var(--text-white);
                border-radius: 50px;
              }
            }
          }
        }

        .menu-bottom-inner {
          position: absolute;
          bottom: 17px;
          width: 100%;
          // padding-top: 28rem !important;

          .menu-bottom {
            display: flex;
            margin-bottom: 14px;

            .bottom-logo {
              background-color: var(--white-bg);
              border-radius: 13px;
              box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1607843137);
              width: 75px;
              height: 75px;
              display: flex;
              margin-right: 15px;

              img {
                width: 50px;
                margin: 0 auto;
                display: flex;
              }
            }

            .content {
              text-align: left;

              p {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 8px;
                font-family: Lato-Bold;
                color: var(--text-white);
              }

              button {
                &.btn-primary {
                  background-color: var(--text-white);
                  width: 65%;
                  height: 40px;
                  border-radius: 50px;
                  color: var(--teal-bg);
                  box-shadow: 0px 3px 6px #00000029;
                  border: 0;
                  outline: 0;
                  font-size: 12px;
                  line-height: 25px;
                  font-family: Lato-Bold;
                  margin-bottom: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    margin-right: 7px;
                    width: 14px;
                    vertical-align: sub;
                  }
                }

                .apple_pay {
                  width: 9px;
                  height: 11px;
                  -webkit-mask-image: url(../images/apple-pay.svg);
                  mask-image: url(../images/apple-pay.svg);
                  background-color: var(--teal-bg);
                  display: block;
                  margin-right: 5px;
                }
              }
            }
          }

          .loyalty-text {
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            color: var(--text-white);
            opacity: 1;
            margin-bottom: 0.4rem;
            font-family: Co Headline;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            em {
              width: 0.8rem;
              display: inline-block;
              margin: 0 0.3rem;

              img {
                animation: plus-heart 1s infinite;
                -webkit-animation: plus-heart 1s infinite;
                vertical-align: middle;
              }

              @keyframes plus-heart {
                0% {
                  transform: scale(1);
                }

                50% {
                  transform: scale(1.2);
                }
              }
            }

            a {
              img {
                width: 97px;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .sd-header {
        .menu-close {
          img {
            margin: 20px 14px 0 0px;
          }
        }
      }
    }

    .menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .menu ul li {
      margin: 0.5rem 0;
    }
  }
}

// @media screen and (max-device-width: 1519px) {
// }
@media only screen and (max-width: 1199px) {
  .header-area {
    .header-left {
      text-align: left;

      a {
        img {
          width: 42px;
        }
      }
    }

    .flex-wrap-nowrap {
      flex-wrap: nowrap;
      align-items: center;

      .menu-button {
        outline: 0;

        span {
          background-color: #fff;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          display: block;
          height: 4px;
          transition: all 0.5s;
          -o-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -webkit-transition: all 0s;
          -moz-transition: all 0.5s;
          width: 1.8rem;
        }

        span+span {
          margin-top: 0.32rem;
        }
      }

      .menu {
        display: none;
        flex-direction: column;
        transition: all 0.5s;
        min-height: 100vh;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 1;
        transition: 0.5s;
      }

      .menu.open {
        right: -38px;
        width: 376px;

        .hide-desk {
          padding: 30px 17px;

          .logo {
            text-align: center;

            img {
              width: 100px;
              margin-bottom: 5px;
            }

            h2 {
              font-size: 16px;
              line-height: 30px;
              color: #fcbf49;
              font-family: Lato-Bold;
              margin-bottom: 3px;
            }

            p {
              // color: #a5a5a5;
              font-size: 16px;
              cursor: pointer;
            }
          }

          .meno-menu {
            .mobo-menu {
              text-align: left;

              li {
                margin: 0px;
                border-bottom: 1px solid #e0e0e075;
                padding: 25px 0;

                &:last-child {
                  border-bottom: 0;
                }

                a {
                  color: #fff;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 24px;
                  font-family: Lato-Bold;

                  p {
                    margin-bottom: 0;
                    color: #a5a5a5;
                    font-size: 12px;
                    margin-left: 22px;
                  }

                  img {
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          // .signup-btn {
          //   margin-top: 42px;

          //   button {
          //     &.btn-primary {
          //       background-color: #fcbf49;
          //       width: 100%;
          //       height: 50px;
          //       border-radius: 50px;
          //       color: #003049;
          //       box-shadow: 0px 3px 6px #00000029;
          //       border: 0;
          //       outline: 0;
          //       font-size: 16px;
          //       line-height: 25px;
          //       font-family: Lato-Bold;
          //       margin-bottom: 15px;

          //       &.login-btn {
          //         background-color: #003049;
          //         color: #fff;
          //         border: 2px solid #ffffff;
          //       }

          //       &.Sign-out-btn {
          //         background-color: #003049;
          //         color: #fcbf49;
          //         border: 2px solid #fcbf49;
          //         border-radius: 50px;
          //       }
          //     }
          //   }
          // }

          .menu-bottom-inner {
            position: absolute;
            bottom: 17px;
            width: 100%;
            // padding-top: 28rem !important;

            .menu-bottom {
              display: flex;
              margin-bottom: 40px;

              .bottom-logo {
                background-color: var(--white-bg);
                border-radius: 13px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1607843137);
                width: 75px;
                height: 75px;
                display: flex;
                margin-right: 15px;

                img {
                  width: 50px;
                  margin: 0 auto;
                  display: flex;
                }
              }

              .content {
                text-align: left;

                p {
                  font-size: 12px;
                  line-height: 16px;
                  margin-bottom: 8px;
                  font-family: Lato-Bold;
                  color: var(--text-white);
                }

                button {
                  &.btn-primary {


                    img {
                      margin-right: 7px;
                      width: 14px;
                      vertical-align: sub;
                    }
                  }
                }
              }
            }

            .loyalty-text {
              font-size: 12px;
              line-height: 1;
              font-weight: 500;
              color: #fff;
              opacity: 1;
              margin-bottom: 0.4rem;
              font-family: Co Headline;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;

              em {
                width: 0.8rem;
                display: inline-block;
                margin: 0 0.3rem;

                img {
                  animation: plus-heart 1s infinite;
                  -webkit-animation: plus-heart 1s infinite;
                  vertical-align: middle;
                }

                @keyframes plus-heart {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }
                }
              }

              a {
                img {
                  width: 97px;
                  margin-left: 6px;
                }
              }
            }
          }
        }

        .sd-header {
          .menu-close {
            img {
              margin: 20px 14px 0 0px;
            }
          }
        }
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu ul li {
        margin: 0.5rem 0;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .header-area {
    .header-left {
      text-align: left;

      a {
        img {
          width: 42px;
        }
      }
    }

    .flex-wrap-nowrap {
      flex-wrap: nowrap;
      align-items: center;

      .menu-button {
        outline: 0;

        span {
          background-color: #fff;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          display: block;
          height: 4px;
          transition: all 0.5s;
          -o-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -webkit-transition: all 0s;
          -moz-transition: all 0.5s;
          width: 1.8rem;
        }

        span+span {
          margin-top: 0.32rem;
        }
      }

      .menu {
        display: none;
        flex-direction: column;
        transition: all 0.5s;
        min-height: 100vh;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 1;
        transition: 0.5s;
      }

      .menu.open {
        right: 0px;
        width: 376px;

        .hide-desk {
          padding: 30px 17px;

          .logo {
            text-align: center;

            img {
              width: 100px;
              margin-bottom: 5px;
            }

            h2 {
              font-size: 16px;
              line-height: 30px;
              color: #fcbf49;
              font-family: Lato-Bold;
              margin-bottom: 3px;
            }

            p {
              // color: #a5a5a5;
              font-size: 16px;
              cursor: pointer;
            }
          }

          .meno-menu {
            .mobo-menu {
              text-align: left;

              li {
                margin: 0px;
                border-bottom: 1px solid #e0e0e075;
                padding: 25px 0;

                &:last-child {
                  border-bottom: 0;
                }

                a {
                  color: #fff;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 24px;
                  font-family: Lato-Bold;

                  p {
                    margin-bottom: 0;
                    color: #a5a5a5;
                    font-size: 12px;
                    margin-left: 22px;
                  }

                  img {
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          // .signup-btn {
          //   margin-top: 42px;

          //   button {
          //     &.btn-primary {
          //       background-color: #fcbf49;
          //       width: 100%;
          //       height: 50px;
          //       border-radius: 50px;
          //       color: #003049;
          //       box-shadow: 0px 3px 6px #00000029;
          //       border: 0;
          //       outline: 0;
          //       font-size: 16px;
          //       line-height: 25px;
          //       font-family: Lato-Bold;
          //       margin-bottom: 15px;

          //       &.login-btn {
          //         background-color: #003049;
          //         color: #fff;
          //         border: 2px solid #ffffff;
          //       }

          //       &.Sign-out-btn {
          //         background-color: #003049;
          //         color: #fcbf49;
          //         border: 2px solid #fcbf49;
          //         border-radius: 50px;
          //       }
          //     }
          //   }
          // }

          .menu-bottom-inner {
            position: absolute;
            bottom: 17px;
            width: 100%;
            // padding-top: 28rem !important;

            .menu-bottom {
              display: flex;
              margin-bottom: 40px;

              .bottom-logo {
                background-color: var(--white-bg);
                border-radius: 13px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1607843137);
                width: 75px;
                height: 75px;
                display: flex;
                margin-right: 15px;

                img {
                  width: 50px;
                  margin: 0 auto;
                  display: flex;
                }
              }

              .content {
                text-align: left;

                p {
                  font-size: 12px;
                  line-height: 16px;
                  margin-bottom: 8px;
                  font-family: Lato-Bold;
                  color: #fff;
                }

                button {
                  &.btn-primary {
                    background-color: var(--white-bg);
                    width: 65%;
                    height: 40px;
                    border-radius: 50px;
                    color: var(--text-teal);
                    box-shadow: 0px 3px 6px #00000029;
                    border: 0;
                    outline: 0;
                    font-size: 12px;
                    line-height: 25px;
                    font-family: Lato-Bold;
                    margin-bottom: 15px;

                    img {
                      margin-right: 7px;
                      width: 14px;
                      vertical-align: sub;
                    }
                  }
                }
              }
            }

            .loyalty-text {
              font-size: 12px;
              line-height: 1;
              font-weight: 500;
              color: #fff;
              opacity: 1;
              margin-bottom: 0.4rem;
              font-family: Co Headline;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;

              em {
                width: 0.8rem;
                display: inline-block;
                margin: 0 0.3rem;

                img {
                  animation: plus-heart 1s infinite;
                  -webkit-animation: plus-heart 1s infinite;
                  vertical-align: middle;
                }

                @keyframes plus-heart {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }
                }
              }

              a {
                img {
                  width: 97px;
                  margin-left: 6px;
                }
              }
            }
          }
        }

        .sd-header {
          .menu-close {
            img {
              margin: 20px 14px 0 0px;
            }
          }
        }
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu ul li {
        margin: 0.5rem 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-area {
    height: 79px;
    display: flex;
    align-items: center;
    .header-left {
      text-align: left;

      a {
        img {
          width: 42px;
          height: 44px;
        }
      }
    }

    .header-right {
      display: flex;
    }

    .flex-wrap-nowrap {
      flex-wrap: nowrap;
      align-items: center;

      .menu-button {
        outline: 0;
        padding-right: 0;

        span {
          background-color: #fff;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          display: block;
          height: 4px;
          transition: all 0.5s;
          -o-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -webkit-transition: all 0s;
          -moz-transition: all 0.5s;
          width: 1.8rem;
        }

        span+span {
          margin-top: 0.32rem;
        }
      }

      .menu {
        display: none;
        flex-direction: column;
        transition: all 0.5s;
        min-height: 100vh;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 1;
        transition: 0.5s;
      }

      .menu.open {
        // display: block;
        position: absolute;
        top: -23px;
        width: 305px;

        .hide-desk {
          padding: 30px 23px;

          .logo {
            text-align: center;

            img {
              width: 100px;
              margin-bottom: 5px;
            }

            h2 {
              font-size: 16px;
              line-height: 30px;
              color: #fcbf49;
              font-family: Lato-Bold;
              margin-bottom: 3px;
            }

            p {
              // color: #a5a5a5;
              font-size: 16px;
              cursor: pointer;
            }
          }

          .meno-menu {
            .mobo-menu {
              text-align: left;

              li {
                margin: 0px;
                border-bottom: 1px solid #e0e0e075;
                padding: 25px 0;

                &:last-child {
                  border-bottom: 0;
                }

                a {
                  color: #fff;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 24px;
                  font-family: Lato-Bold;

                  p {
                    margin-bottom: 0;
                    color: #a5a5a5;
                    font-size: 12px;
                    margin-left: 22px;
                  }

                  img {
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          .signup-btn {
            margin-top: 42px;

            button {
              &.btn-primary {
                width: 100%;
                height: 50px;
                border: 0;
                outline: 0;
                font-size: 16px;
                line-height: 25px;
                font-family: Lato-Bold;
                margin-bottom: 15px;
              }
            }
          }

          .menu-bottom-inner {
            position: absolute;
            bottom: 17px;
            width: 100%;
            // padding-top: 28rem !important;

            .menu-bottom {
              display: flex;
              margin-bottom: 40px;

              .bottom-logo {
                background-color: var(--white-bg);
                border-radius: 13px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1607843137);
                width: 75px;
                height: 75px;
                display: flex;
                margin-right: 15px;

                img {
                  width: 50px;
                  margin: 0 auto;
                  display: flex;
                }
              }

              .content {
                text-align: left;

                p {
                  font-size: 12px;
                  line-height: 16px;
                  margin-bottom: 8px;
                  font-family: Lato-Bold;
                  color: #fff;
                }

                button {
                  &.btn-primary {
                    width: 65%;
                    height: 40px;
                    border-radius: 50px;
                    font-size: 12px;
                    line-height: 25px;
                    font-family: Lato-Bold;
                    margin-bottom: 15px;

                    img {
                      margin-right: 7px;
                      width: 14px;
                      vertical-align: sub;
                    }
                  }
                }
              }
            }

            .loyalty-text {
              font-size: 12px;
              line-height: 1;
              font-weight: 500;
              color: #fff;
              opacity: 1;
              margin-bottom: 0.4rem;
              font-family: Co Headline;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;

              em {
                width: 0.8rem;
                display: inline-block;
                margin: 0 0.3rem;

                img {
                  animation: plus-heart 1s infinite;
                  -webkit-animation: plus-heart 1s infinite;
                  vertical-align: middle;
                }

                @keyframes plus-heart {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }
                }
              }

              a {
                img {
                  width: 97px;
                  margin-left: 6px;
                }
              }
            }
          }
        }

        .sd-header {
          .menu-close {
            img {
              margin: 20px 14px 0 0px;
            }
          }
        }
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu ul li {
        margin: 0.5rem 0;
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .header-area {
    .flex-wrap-nowrap {
      .menu.open {
        .hide-desk {
          padding: 0px 23px;
           .logo {
             img {
                margin-bottom: 24px;
             }
           }
          .signup-btn {
            margin-top: 0;
          }

          .menu-bottom-inner {
            bottom: 0;
            .menu-bottom {
              margin-bottom: 0;
            }
            .loyalty-text {
              margin-bottom: 1.4rem;
            }
          }
        }
      }
    }
  }
}