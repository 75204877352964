.main-wrapper {
  &#product-details-page {
    padding: 0;

    .product-details {
      position: relative;

      .product-details-img {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0px;
          width: 100%;
          height: 16%;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url(../images/box-show.svg);
        }

        &::before {
          content: "";
          position: absolute;
          // top: 108px;
          width: 100%;
          height: 16%;
          left: 0;
          right: 0;
          bottom: 0;
          transform: rotate(180deg);
          background-image: url(../images/box-show.svg);
        }
      }

      .content {
        position: absolute;
        top: 24px;
        display: flex;
        justify-content: space-between;
        left: 0;
        right: 0;
        padding: 0 13rem;
        margin-bottom: 50px;

        a {

          img {
            width: 30px;
            height: 30px;
            border-radius: 50px;
            background-color: var(--white-bg);
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 3px 6px #00000029;
            padding: 8px 8px;
          }
        }

        button {
          &.btn-primary {
            background-color: var(--teal-bg);
            border-radius: 50px;
            width: 100px;
            height: 30px;
            font-size: 12px;
            font-family: Lato-Bold;
            line-height: 18px;
            text-transform: uppercase;
            color: var(--text-white);
            border: 0;
            outline: 0;
            box-shadow: none;
          }
        }
      }

      .product-container {
        padding: 20px 0 30px;

        .product-content {
          h2 {
            font-size: 23px;
            line-height: 32px;
            font-family: Co Headline Bold;
            color: var(--text-teal);
            margin-bottom: 5px;
          }

          h6 {
            font-size: 18px;
            line-height: 23px;
            font-family: Co Headline Bold;
            // color: var(--text-teal);
            margin-bottom: 9px;
            color: #003049;

            .required {
              &.disabled {
                background-color: #767676;
                color: #fff;
                padding: 0 9px;
                border-radius: 25px;
                /* margin-left: 5px; */
                font-size: 11px;
                font-family: Lato-Regular;
                line-height: 18px;
                letter-spacing: 0.2px;
                font-weight: 400;
                display: flex;
                align-items: center;
                outline: none;

                button {
                  border: 0;
                  background-color: transparent;
                  color: #fff;
                  outline: none;
                }
              }

              background-color: #00ba2b;
              color: #fff;
              padding: 0 9px;
              border-radius: 25px;
              /* margin-left: 5px; */
              font-size: 11px;
              font-family: Lato-Regular;
              line-height: 18px;
              letter-spacing: 0.2px;
              font-weight: 400;
              display: flex;
              align-items: center;
              outline: none;

              button {
                border: 0;
                background-color: transparent;
                color: #fff;
                outline: none;

                img {
                  margin-right: 5px;
                }
              }
            }
          }

          p {
            color: #767676;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
            font-family: Lato-Regular;

            &.heading {
              color: #343434;
            }
          }

          border-bottom: 1px solid hsla(0, 0%, 10.38%, 0.15);
          padding-bottom: 30px;
          margin-bottom: 20px;
          position: relative;

          &:nth-child(2) {
            padding-bottom: 16px;
          }

          &:nth-child(3) {
            padding-bottom: 16px;
          }

          &:nth-child(4) {
            padding-bottom: 0px;
            margin-bottom: 0;
            border-bottom: 0;

            h6 {
              margin-bottom: 22px;
            }
          }

          .icon {
            background-color: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
            border-radius: 50px;
            width: 108px;
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
              display: block;
              // width: 12px;
              // &.plus-icon {
              //     width: 25px;
              // }

              &.minus_icon {
                width: 35px !important;
                padding: 12px;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;

              }

              &.plus-icon {
                padding: 12px;
                width: 36px;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
              }

              &.delete_icon {
                width: 35px !important;
                padding: 12px;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
              }
            }

            h3 {
              font-size: 18px;
              margin-bottom: 0;
              font-family: Lato-Bold;
              width: 21px;
              display: flex;
              justify-content: center;
            }
          }

          .choose-box {
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
            padding: 12px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .choose-content {
              .radio_icon {
                display: flex;
              }

              h5 {
                font-size: 13px;
                font-family: Co Headline Bold;
                color: #003049;
                line-height: 18px;
                margin-bottom: 0px;
              }

              p {
                color: #343434;
                font-size: 10px;
                font-family: Lato-Regular;
                line-height: 12px;
                margin-bottom: 0;
              }
            }

            .choose-icon {
              display: flex;

              h3 {
                margin: 0 20px;
                font-size: 18px;
                color: #003049;
                font-family: Lato-Bold;
              }
            }
          }
        }

        .add-order-btn {
          margin: 30px auto 0;
          width: 30%;

          button {
            &.btn-primary {
              border-radius: 30px;
              width: 100%;
              height: 50px;
              background-color: var(--teal-bg);
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
              color: var(--text-white);
              border: 0;
              font-size: 16px;
              font-family: Lato-Bold;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              outline: none;
            }
          }
        }
      }
    }
  }
}

.hot-sour {
  padding-left: 0 !important;

  .modal-dialog {
    .modal-content {
      border-radius: 25px;
      border: 0;
      background-color: var(--white-bg);

      .modal-header {
        border-bottom: 0;
        justify-content: flex-end;

        button {
          background-color: transparent;

          &.btn-close {
            background-image: url(../images/close-icon.svg);
            border: 0;
            height: 12px;
            width: 12px;
            outline: 0;
          }
        }
      }

      .modal-body {
        .product-container {
          .product-content {
            h2 {
              font-size: 21px;
              line-height: 30px;
              font-family: Co Headline Bold;
              margin-bottom: 9px;
              color: var(--text-teal);
            }

            h6 {
              font-size: 18px;
              line-height: 30px;
              font-family: Co Headline Bold;
              margin-bottom: 9px;
              color: var(--text-teal);
            }

            .choose-box {
              background-color: #fff;
              border-radius: 15px;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
              padding: 12px 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              .radio_icon {
                display: flex;
              }

              h5 {
                font-size: 16px;
                line-height: 26px;
                font-family: Co Headline Bold;
                margin-bottom: -3px;
                color: #003049;
              }

              .choose-icon {
                text-align: center;

                h3 {
                  font-size: 16px;
                  line-height: 30px;
                  font-family: Co Headline Bold;
                  margin-bottom: 0px;
                  color: #003049;
                }
              }
            }

            .icon {
              background-color: #fff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
              border-radius: 50px;
              width: 108px;
              height: 40px;
              display: flex;
              justify-content: space-around;
              align-items: center;

              img {
                display: block;

                &.minus_icon {
                  width: 35px !important;
                  padding: 12px;
                  -webkit-touch-callout: none;
                  -webkit-tap-highlight-color: transparent;
                }

                &.plus-icon {
                  padding: 12px;
                  width: 36px;
                  -webkit-touch-callout: none;
                  -webkit-tap-highlight-color: transparent;
                }

                &.delete_icon {
                  width: 35px !important;
                  padding: 12px;
                  -webkit-touch-callout: none;
                  -webkit-tap-highlight-color: transparent;
                }
              }

              h3 {
                font-size: 16px;
                margin-bottom: 0;
                font-family: Lato-Bold;
                width: 21px;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
        padding: 10px 30px;

        .add-order-btn {
          button {
            &.btn-primary {
              border-radius: 5px;
              width: 100%;
              height: 50px;
              background-color: #fff;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
              color: #003049;
              border: 0;
              font-size: 16px;
              font-family: Lato-Bold;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              outline: none;
              padding: 0 27px;
            }
          }
        }

        button {
          &.btn-primary {
            border-radius: 5px;
            width: 20%;
            height: 50px;
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
            color: #003049;
            border: 0;
            font-size: 16px;
            font-family: Lato-Bold;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            outline: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .main-wrapper {
    &#product-details-page {
      .product-details {
        position: relative;

        .product-details-img {
          &::after {
            height: 32%;
          }

          &::before {
            // top: 108px;
            height: 32%;
          }
        }

        .product-container {
          .add-order-btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-wrapper {
    &#product-details-page {
      .product-details {
        .product-details-img {
          &::after {
            height: 44%;
          }

          &::before {
            // top: 108px;
            height: 51%;
          }
        }

        .product-container {
          .add-order-btn {
            position: fixed;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            padding: 24px 20px;
            z-index: 1000;
            cursor: pointer;
            margin-top: 0;
          }
        }

        .content {
          padding: 0px 15px;
          top: 0;

          a {
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .hot-sour {
    .modal-dialog {
      .modal-content {
        .modal-footer {
          .add-order-btn {
            button {
              &.btn-primary {
                padding: 0 40px;
              }
            }
          }

          button {
            &.btn-primary {
              width: 40%;
            }
          }
        }
      }
    }
  }
}