.your-orders {
  padding: 24px 0;
  background-color: #faf8f8;
  h6 {
    color: var(--text-teal);
    font-family: Co Headline Bold;
    font-size: 16px;
    line-height: 30px;
  }

  .date-inner-title {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    P {
      margin-bottom: 0;
      font-size: 21px;
      color: var(--teal-bg);
      line-height: 30px;
      font-family: Co Headline Bold;
    }

    .shoping-img {
      width: 22px;
      height: 22px;
    }
  }

  .order-details {
    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .order-details-title {
      h2 {
        margin-bottom: 0;
        font-size: 21px;
        color: var(--teal-bg);
        line-height: 30px;
        font-family: Co Headline Bold;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
        color: #003049;
        line-height: 30px;
        font-family: Lato-Bold;
      }
    }

    .order-details-text {
      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          display: flex;
          justify-content: flex-start;
          padding: 20px 0;
          border-bottom: 1px solid #ccc;
          align-items: center;

          &:last-child {
            border-bottom: 0;
          }

          // &:nth-child(3) {
          //     p {
          //         font-family: Lato-Bold;
          //     }
          // }

          // &:nth-child(4) {
          //     p {
          //         font-family: Lato-Bold;
          //     }
          // }

          h4 {
            background-color: #f77f00;
            border-radius: 30px;
            width: 25px;
            height: 25px;
            display: flex;
            font-size: 12px;
            color: #fff;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            box-shadow: 0px 1px 3px #00000029;
          }

          p {
            margin-bottom: 0;
            margin-left: 10px;
            color: var(--teal-bg);
            font-size: 14px;
            line-height: 30px;
            font-family: Co Headline Bold;
          }

          img {
            width: 22px;
            margin-bottom: 0;
          }
        }
      }
    }

    .checkout-btn {
      padding-top: 30px;

      .checkout-btn-to {
        width: 50%;
        margin: 0 auto;
        display: flex;

        .btn-primary {
          background-color: #f77f00;
          border-radius: 30px;
          box-shadow: 0px 1px 3px #00000029;
          color: #fff;
          font-weight: 600;
          border: 0;
          font-size: 16px;
          line-height: 25px;
          // font-family: Lato-Bold;
          height: 50px;
          width: 100%;
          outline: 0;
          margin-right: 20px;

          &.red {
            background-color: #d62828;
          }
        }
      }
    }
  }

  .product-content {
    h6 {
      font-size: 18px;
      line-height: 23px;
      font-family: Co Headline Bold;
      color: var(--teal-bg);
      margin-bottom: 15px;
    }

    &.live-order {
      padding-bottom: 20px;

      .choose-box {
        background-color: var(--teal-bg);
        color: var(--text-white);

        .choose-content {
          ul {
            li {
              .icon {
                img {
                  filter: brightness(0) invert(1);
                }
              }
            }
          }
        }
      }
    }

    .choose-box {
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
      padding: 12px 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;

      .choose-content {
        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            display: flex;
            margin-bottom: 13px;

            &:nth-child(1) {
              .icon {
                img {
                  width: 17px;
                }
              }
            }

            &:nth-child(2) {
              .icon {
                img {
                  width: 13px;
                }
              }
            }

            &:nth-child(3) {
              .icon {
                img {
                  width: 17px;
                }
              }
            }

            &:nth-child(4) {
              .icon {
                img {
                  width: 17px;
                }
              }
            }

            .icon {
              border-radius: 0px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            .content {
              flex-wrap: nowrap;
              display: inline-flex;
              // margin-left: 13px;
              flex-direction: column;
              position: absolute;
              left: 17%;

              p {
                margin-bottom: 0;
                font-size: 14px;
                font-family: "Lato";
              }
            }
          }
        }
      }

      .choose-icon {
        background-color: #fff;
        box-shadow: 0px 1px 8px #00000029;
        width: 25px;
        height: 25px;
        border-radius: 30px;
        display: flex;
        align-items: center;

        img {
          width: 10px;
          height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
        }
      }
    }
  }

  .no-order-section {
    .section-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px solid #4444;
      margin-bottom: 50px;

      .order-details-title {
        p {
          font-size: 12px;
          line-height: 18px;
          color: #767676;
          margin-bottom: 5px;
          font-family: Lato-Bold;
        }

        h2 {
          font-size: 13px;
          color: #003049;
          line-height: 18px;
          font-family: Lato-Bold;
          margin-bottom: 0;

          img {
            margin-left: 9px;
          }
        }
      }

      .search-bar {
        border-radius: 30px;
        background-color: #fff;
        color: #003049;
        font-size: 11px;
        font-family: Lato-Bold;
        border: 0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;

        img {
          width: 17px;
        }
      }
    }

    .section-inner-img {
      text-align: center;

      img {
        width: 40%;
        padding-bottom: 50px;
      }
    }

    .section-inners {
      .order-details-title {
        text-align: center;

        h2 {
          margin-bottom: 6px;
          font-size: 21px;
          color: #003049;
          line-height: 30px;
          font-family: Co Headline Bold;
        }

        p {
          color: #767676;
          font-size: 12px;
          line-height: 30px;
          font-family: Lato-Bold;
          margin-bottom: 0;
        }
      }

      button {
        &.primary-btn {
          width: 30%;
          margin: 0 auto;
          border-radius: 50px;
          height: 50px;
          font-size: 16px;
          line-height: 25px;
          color: #fff;
          font-family: Lato-Bold;
          margin-top: 15px;
          margin-bottom: 20px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f77f00;
          outline: none;
          border: 0;
          box-shadow: 0px 3px 6px #00000029;
        }
      }
    }

    .menu-bottom {
      display: flex;
      margin-bottom: 35px;
      justify-content: center;
      padding-top: 80px;

      .bottom-logo {
        background-color: #003049;
        border-radius: 13px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1607843137);
        width: 75px;
        height: 75px;
        display: flex;
        margin-right: 15px;

        img {
          width: 50px;
          margin: 0 auto;
          display: flex;
        }
      }

      .content {
        text-align: left;

        p {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 8px;
          font-family: Lato-Bold;
          color: #003049;
        }

        button {
          &.btn-primary {
            background-color: #fff;
            width: 65%;
            height: 40px;
            border-radius: 50px;
            color: #003049;
            box-shadow: 0px 3px 6px #00000029;
            border: 0;
            outline: 0;
            font-size: 12px;
            line-height: 25px;
            font-family: Lato-Bold;
            margin-bottom: 15px;

            img {
              margin-right: 7px;
              width: 14px;
              vertical-align: sub;
            }
          }
        }
      }
    }
  }
}

.receipt-modal {
  .modal-dialog {
    width: 25%;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    position: relative;
  }

  .modal-content {
    border-radius: 25px;
    background-color: #fff;
    margin: 3rem 0;

    p {
      color: #fff;
      font-size: 14px;
      padding: 0 1rem;
    }

    .modal-header {
      border-bottom: 0;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 24px;
        color: var(--teal-bg);
        line-height: 30px;
        font-family: Co Headline Bold;
        margin-bottom: 0px;
      }

      button {
        border: 0;
        background-color: transparent;

        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        &.btn-close {
          background-image: url(../images/close-icon.svg);
          width: 18px;
          height: 18px;
          background-size: 18px;
          // -webkit-appearance: none;
          // filter: brightness(0) invert(1);
          padding: 0px;
        }
      }
    }

    .modal-body {
      padding: 0 1rem;
      .receipt-section {
        > div {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              display: flex;
              justify-content: space-between;
              padding: 8px 0;

              h2 {
                color: #003049;
                font-size: 15px;
                margin-bottom: 0;
              }

              p {
                color: #767676;
                font-size: 12px;
                margin-bottom: 0;
              }
            }
          }
        }

        .Continue-btn {
          button {
            margin: 30px 0;

            &.primary-btn {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              border-radius: 50px;
              border: 1px solid var(--teal-bg);
              color: var(--text-white);
              background-color: var(--teal-bg);
              font-family: Lato-Bold;
              font-size: 17px;
              align-items: center;
              box-shadow: 0px 3px 6px #00000029;

              &:focus-visible {
                outline: none;
                box-shadow: none;
                border: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .your-orders {
    .order-details {
      .checkout-btn {
        .checkout-btn-to {
          width: 100%;
          display: block;

          .btn-primary {
            margin-bottom: 15px;
            margin-right: 0;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .product-content {
      .choose-box {
        .choose-content {
          ul {
            li {
              .content {
                left: 63px;
              }
            }
          }
        }
      }
    }

    .no-order-section {
      .section-inners {
        button {
          &.primary-btn {
            width: 100%;
          }
        }
      }

      .section-inner-img {
        img {
          width: 224px;
          padding-bottom: 30px;
        }
      }
    }
  }
  .receipt-modal {
    .modal-dialog {
      width: 96%;
      position: absolute;
    }
  }
}
