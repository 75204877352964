.accout-page {
  background-color: var(--teal-bg);
  padding: 31px 0;
  opacity: .90;

  h1 {
    margin-bottom: 0;
    color: var(--text-white);
    font-size: 21px;
    line-height: 30px;
    font-family: Co Headline;
  }

  #account-info {
    .account-user {
      text-align: center;
      margin-bottom: 30px;
    }

    .account-info-content {
      padding-bottom: 10rem;

      .account-box-content {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid hsl(0deg 0% 99.77% / 33%);
        padding: 18px 0;
        cursor: pointer;

        h4 {
          font-size: 14px;
          font-family: Lato-Bold;
          line-height: 30px;
          color: #fff;
          margin-bottom: 0;
        }

        h2 {
          font-size: 14px;
          font-family: Lato-Bold;
          line-height: 30px;
          color: #fff;
          margin-bottom: 0;

          img {
            margin-left: 18px;
            filter: brightness(0) invert(1);
            transform: rotate(270deg);
            margin-bottom: 0;
            vertical-align: middle;

            &.check-right {
              margin-right: 5px;
              margin-left: 0;
              transform: rotate(0deg);
              filter: none;
              vertical-align: sub;
            }
          }
        }
      }
    }
  }

  .react-tabs {
    .tabs {
      padding: 30px 0;

      >div {
        >div {
          color: #fcbf49;
          border: 2px solid var(--text-teal);
        }
      }
    }
  }

  .update-btn {
    background-color: #003049;
    padding: 25px 0;
    box-shadow: 0px -5px 6px #00000029;
    text-align: center;

    .updated-btn {
      width: 100%;

      .btn-primary {
        background-color: #fcbf49;
        border-radius: 30px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
        color: #fff;
        font-weight: 700;
        border: 0;
        font-size: 16px;
        line-height: 25px;
        height: 50px;
        width: 32%;
        outline: 0;

        &:focus {
          box-shadow: none;
          border-color: #fcbf49;
        }
      }
    }
  }
}

.update-modal {
  .modal-dialog {
    width: 28%;
    left: 0;
    right: 0;
    align-items: center;
    bottom: 0;
    display: flex;
    position: relative;
    top: 0;
    margin: 100px auto;

    &#address-modal {
      margin: 90px auto;
      position: relative;

      .modal-content {
        .modal-body {
          .update-popup-modal-inner {
            .update-popup {
              .form-group-blue {
                textarea {
                  &.form-control {
                    border-radius: 10px;
                    height: 50px;
                    padding: 10px 23px 0;
                    font-size: 15px;
                    line-height: 30px;
                    margin-bottom: 12px;
                  }
                }

                .edit-delete-btn {
                  display: flex;
                  justify-content: end;
                  margin-bottom: 8px;

                  li {
                    display: flex;
                    margin-right: -2px;
                    // background-color: #fff;
                    border-radius: 5px;
                    width: 30px;
                    height: 30px;
                    // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1607843137);
                    font-size: 12px;
                    justify-content: center;
                    align-items: center;

                    &:last-child {
                      margin-right: 0;
                    }

                    a {
                      color: #003049;
                      text-decoration: none;

                      img {
                        width: 12px;
                        height: 14px;
                        vertical-align: sub;
                        filter: brightness(0) invert(1);

                        &.edit-icon {
                          width: 18px;
                          height: 18px;
                          vertical-align: sub;
                        }
                      }
                    }
                  }
                }

                .checkbox-label {
                  position: relative;
                  cursor: pointer;
                  display: flex;
                  font-size: 16px;
                  align-content: center;
                  flex-wrap: wrap;
                  line-height: 17px;
                  justify-content: flex-start;
                  margin-bottom: 0;
                  align-items: center;
                  width: 130px;

                  p {
                    margin-bottom: 0;
                    padding: 0 8px;
                    line-height: 2px;
                    cursor: pointer;
                  }

                  .checkbox-input {
                    opacity: 0;
                    // position: absolute;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                  }

                  input:checked~.checkbox-custom {
                    background-color: #fff;
                    width: 15px;
                    height: 15px;
                    border-radius: 3px;
                  }

                  .checkbox-custom:after {
                    content: "";
                    position: absolute;
                    display: none;
                  }

                  .checkbox-custom:after {
                    content: "";
                    position: absolute;
                    display: none;
                  }

                  input:checked~.checkbox-custom:after {
                    display: block;
                  }

                  .checkbox-custom {
                    border: 2px solid #fff;
                    border-radius: 3px;
                    width: 15px;
                    height: 15px;
                  }

                  .checkbox-custom:after {
                    content: "";
                    left: 4.2px;
                    top: 0.8px;
                    width: 6px;
                    height: 10px;
                    border: solid var(--teal-bg);
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-content {
    border-radius: 25px;
    background-color: var(--teal-bg);
    border: 0;

    p {
      color: var(--text-white);
      font-size: 14px;
      padding: 0 1rem;
    }

    .modal-header {
      border-bottom: 0;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: var(--text-white);
        font-family: Co Headline;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 0;
      }

      button {
        border: 0;
        background-color: transparent;

        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        &.btn-close {
          background-image: url(../images/close-icon.svg);
          width: 20px;
          height: 20px;
          background-size: 20px;
          -webkit-appearance: none;
          filter: brightness(0) invert(1);
          padding: 0;
        }
      }
    }

    .modal-body {
      .update-popup-modal-inner {
        .update-popup {
          .form-group-blue {
            margin-bottom: 15px;

            .flex-wrap-nowrap {
              flex-wrap: nowrap;
              padding: 0 15px;
              justify-content: space-between;
              align-items: center;

              .left-box {
                select {
                  word-wrap: normal;
                  height: 50px;
                  border-radius: 50px;
                  width: 120px;
                  margin-right: 0px;
                  position: relative;
                  top: -7px;
                  padding: 0 18px;
                  // &:focus-visible {
                  //     outline: none;
                  //     box-shadow: none;
                  //     border: none;
                  // }
                  // &:focus {
                  //     outline: none;
                  //     box-shadow: none;
                  //     border: none;
                  // }
                }
              }
            }

            label {
              font-size: 18px;
              color: #fff;
            }

            .form-control {
              border-radius: 50px;
              height: 50px;
              background-color: #faf8f8;
              color: #003049;
              padding: 0 23px;
              margin-bottom: 4px;
              font-size: 16px;
              font-weight: 500;

              &:focus {
                box-shadow: none;
                border-color: #ccc;
              }

              &::-webkit-input-placeholder {
                color: #003049;
              }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
              -webkit-background-fill-color: #fff !important;
            }

            p {
              color: #faf8f8;
            }
          }

          button {
            margin: 30px 0;

            &.primary-btn {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              border-radius: 50px;
              border: 1px solid var(--white-bg);
              color: var(--text-teal);
              background-color: var(--white-bg);
              font-family: Lato-Bold;
              font-size: 17px;
              align-items: center;
              box-shadow: 0px 3px 6px #00000029;

              &:focus-visible {
                outline: none;
                box-shadow: none;
                border: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .update-modal {
    .modal-dialog {
      width: 44%;
    }
  }

  .accout-page {
    .update-btn {
      .updated-btn {
        .btn-primary {
          width: 30%;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .update-modal {
    .modal-dialog {
      width: 50%;
    }
  }

  .accout-page {
    .update-btn {
      .updated-btn {
        .btn-primary {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .update-modal {
    .modal-dialog {
      width: 97%;
      margin: 0 auto;
      position: absolute;
    }
  }

  .accout-page {
    .update-btn {
      .updated-btn {
        .btn-primary {
          width: 100%;
        }
      }
    }
  }
}