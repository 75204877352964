.payment-list {
  margin: 3rem auto;
  width: 500px;
  .date-inner-title {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    a {
      &.back-link {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
    p {
      font-size: 21px;
      color: var(--text-teal);
      line-height: 30px;
      font-family: Co Headline Bold;
      margin-bottom: 9px;
    }
  }

  form {
    button {
      &.primary-btn {
        width: 40%;
        margin: 0 auto;
        border: 0;
        outline: 0;
        background-color: var(--teal-bg);
        border-radius: 30px;
        height: 50px;
        font-size: 16px;
        line-height: 25px;
        color: var(--text-white);
        font-family: Lato-Bold;
        box-shadow: 0px 3px 6px #00000029;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .payment-list {
    margin: 42px 15px 134px;
    width: 92%;

    form {
      button {
        &.primary-btn {
          width: 80%;
        }
      }
    }
  }
}
