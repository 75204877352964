.main-wrapper#ordering-menu-page {
  padding: 0;

  .ordering-menu {
    background-color: #faf8f8;
    padding-bottom: 24px;

    .order-menu-img {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0px;
        width: 100%;
        height: 16%;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../images/box-show.svg);
      }
    }

    .order-banner-logo {
      position: relative;
      padding-bottom: 30px;

      img {
        width: 77px;
        position: relative;
        top: 0;
        left: -7px;
        margin-top: -35px;
        display: none;
      }
    }

    .allergy-information {
      strong {
        font-size: 18px;
        font-family: Lato-Bold;
        line-height: 30px;
        color: var(--text-teal);
      }
    }

    .order-container {
      h2 {
        font-size: 28px;
        line-height: 32px;
        font-family: Lato-Bold;
        color: var(--text-teal);
        margin: 3px 0 3px 0;
      }

      ul {
        padding: 0;
        list-style: none;
        margin: 0 0 26px;
        display: flex;

        li {
          margin-right: 10px;
          font-size: 14px;
          line-height: 16px;
          color: #767676;
          font-family: Lato-Regular;
          font-weight: 300;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            color: #767676;
          }

          &:last-child {
            margin-right: 0;
          }

          img {
            margin-right: 5px;
            width: 13px;
            filter: invert(91%) sepia(32%) saturate(7572%) hue-rotate(0deg) brightness(25%) contrast(11%);
          }
        }
      }
    }

    .main-menu {
      ul {
        padding: 0;
        margin: 0 0 20px;
        list-style: none;
        display: flex;

        li {
          width: 110px;
          height: 40px;
          border-radius: 30px;
          border: 1px solid #d62828;
          background-color: #fff;
          margin-right: 8px;
          display: flex;
          text-align: center;
          justify-content: center;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
          cursor: pointer;
          color: #d62828;
          font-size: 12px;
          line-height: 16px;
          font-family: Lato-Bold;
          font-weight: 400;
          align-items: center;

          &:hover {
            background-color: #d62828;
            color: #fff;
          }

          &.active {
            background-color: #d62828;
            color: #fff;
          }
        }
      }
    }

    #custom-navigation {
      overflow: auto;
      margin: 0 -15px;
      padding: 7px 15px;
      position: relative;

      &::-webkit-scrollbar {
        width: 50px;
        /* Height of the scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff00;
        /* Scrollbar color */
        border-radius: 0px;
        position: absolute;
        top: 0;
        height: 50px;
        width: 100%;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        /* Track background */
      }

      ul {
        padding: 0;
        list-style: none;
        display: flex;
        width: 100%;
        margin: 0;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        height: 60px;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          width: 134px;
          height: 40px;
          border-radius: 30px;
          border: 1px solid var(--teal-bg);
          background-color: var(--white-bg);
          margin-right: 8px;
          display: flex;
          justify-content: center;
          box-shadow: 0px 3px 6px #00000029;
          cursor: pointer;

          a {
            color: var(--text-teal);

            &.active {
              background-color: var(--teal-bg);
              color: var(--text-white);
              border-radius: 30px;

              a {
                color: var(--text-white);
              }
            }
          }

          &.active {
            background-color: var(--teal-bg);

            a {
              color: var(--text-white);
            }
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            color: var(--text-teal);
            text-decoration: none;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-size: 12px;
            line-height: 16px;
            font-family: Lato-Bold;
            font-weight: 400;
            width: 134px;
            padding: 0 11px;
          }

          &:hover {
            background-color: var(--teal-bg);

            a {
              color: var(--text-white);
            }
          }

          &.search-bar {
            border: 0;
            background-color: transparent;
            width: 160px;
            margin-right: 0px;
            vertical-align: middle;
            height: 24px;
            position: relative;
            top: 10px;
            box-shadow: none;
            margin-right: 10px;

            .sidebar__icon {
              width: 22px;
              height: 22px;
              -webkit-mask-image: url(../images/search-icon.svg);
              mask-image: url(../images/search-icon.svg);
              background-color: var(--teal-bg);
              display: block;
            }

            .form-control {
              border: 1px solid var(--teal-bg);
              background-color: transparent;
              width: 90px;
              margin-right: 4px;
              vertical-align: middle;
              position: relative;
              top: -9px;
              box-shadow: none;
              font-size: 12px;
              line-height: 16px;
              font-family: Lato-Bold;
              font-weight: 400;
              border-radius: 30px;
              height: 40px;
              color: var(--text-teal);
              left: 5px;

              // text-align: center;
              &:focus {
                box-shadow: none;
                border: 1px solid var(--teal-bg);
                color: var(--text-teal);
              }

              &:focus-visible {
                outline: none;
                box-shadow: none;
                border: 1px solid var(--teal-bg);
                color: var(--text-teal);
              }

              &::-webkit-input-placeholder {
                color: var(--text-teal);
              }
            }

            .close-btn {
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-59%);
              font-size: 20px;
              cursor: pointer;
              display: none;
              color: var(--text-teal);
            }

            #search-input:not(:placeholder-shown)+#close-btn {
              display: block;
            }
          }
        }
      }

      &.fixed {
        ul {
          height: auto;

          li {
            border: 1px solid var(--white-bg);
            background-color: var(--teal-bg);

            &.search-bar {
              border: 0;

              .sidebar__icon {
                background-color: var(--white-bg);
              }

              &:hover {
                border: 0;
              }
            }

            a {
              color: var(--text-white);

              &.active {
                background-color: var(--text-white);
                border: var(--white-bg);
                color: var(--text-teal);

                a {
                  color: var(--text-teal);
                }
              }
            }

            // &.active {
            //   background-color: var(--white-bg);
            //   border: 1px solid var(--white-bg);

            a {
              color: var(--text-teal);
            }

            // }

            a {
              color: var(--text-white);
            }

            &:hover {
              background-color: var(--teal-bg);
              border: 1px solid var(--white-bg);

              a {
                color: var(--text-teal) !important;
                background-color: var(--white-bg);
                border-radius: 30px;
              }
            }

            &.search-bar {
              background-color: transparent;

              .form-control {
                border: 1px solid var(--white-bg);
                background-color: transparent;
                color: var(--text-white);

                &:focus {
                  border: 1px solid var(--white-bg);
                  color: var(--text-white);
                }

                &:focus-visible {
                  border: 1px solid var(--white-bg);
                  color: var(--text-white);
                }

                &::-webkit-input-placeholder {
                  color: var(--text-white);
                }
              }

              .close-btn {
                color: var(--text-white);
              }
            }
          }
        }
      }
    }

    .view-order-section {
      background-color: var(--teal-bg);
      bottom: 0;
      left: 0;
      padding-bottom: 30px;
      padding-top: 30px;
      position: fixed;
      right: 0;
      text-align: center;
      z-index: 1040;
      width: 100%;

      .view-order-btn {
        background-color: var(--white-bg);
        border-radius: 30px;
        box-shadow: 0px 1px 3px #00000029;
        color: var(--text-teal);
        font-weight: 600;
        border: 0;
        font-size: 16px;
        line-height: 25px;
        font-family: Lato-Bold;
        height: 64px;
        width: 30%;
        outline: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        span {
          &.cart-length {
            position: absolute;
            right: 13px;
            background-color: var(--teal-bg);
            color: var(--text-white);
            border-radius: 84%;
            padding: 7px 7px;
            width: 40px;
            height: 40px;
            font-size: 20px;
          }

          &.view-order-text {
            font-size: 12px;
            line-height: 16px;
            font-family: Lato-Bold;
            font-weight: 600;
          }
        }
      }
    }

    .tab {
      width: 100%;
      padding: 10px;
      text-align: center;
      transition: position 0.3s, top 0.3s;

      &.fixed {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1060;
        display: flex;
        justify-content: flex-start;
        background: var(--teal-bg);
        padding: 18px 13rem 18px 13rem !important;
        margin: 0 auto !important;
        transition: position 0.3s, top 0.3s;
      }
    }

    .element {
      h2 {
        font-size: 18px;
        font-family: Co Headline Bold;
        line-height: 27px;
        margin-bottom: 15px;
        color: var(--text-teal);
      }

      :nth-child(3) {
        .out-of-stock {
          .text-cotainer {
            button {
              &.btn-primary {
                background-color: #d62828;
                outline: none;
              }
            }
          }
        }
      }
    }

    .delivered-inner {
      .special-offers {
        h2 {
          font-size: 18px;
          font-family: Co Headline Bold;
          line-height: 27px;
          margin-bottom: 15px;
        }
      }

      .special-offer-boxs {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
        box-shadow: 0px 1px 3px #00000029;
        display: flex;
        height: 100%;
        justify-content: space-between;
        position: relative;

        span {
          span.react-loading-skeleton {
            border-radius: 0 15px 15px 0;
          }
        }

        &.out-of-stock {
          background-color: #f5f5f5;

          .text-container {
            button {
              &.btn-primary {
                background-color: #d62828 !important;
                outline: none;
              }
            }
          }
        }

        .text-container {
          padding: 10px 0 12px 10px;

          .right-side {
            span {
              span.react-loading-skeleton {
                position: absolute;
                top: 20px;
                right: 272px;
              }
            }
          }

          span {
            span.react-loading-skeleton {
              position: relative;
              z-index: 99;
            }
          }

          button {
            &.btn-primary {
              background-color: var(--teal-bg) !important;
              border: 0;
              border-radius: 30px;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
              color: var(--text-white);
              font-family: Lato-Bold;
              font-size: 11px;
              line-height: 14px;
              margin-bottom: 9px;
              padding: 2px 10px;
            }
          }

          h5 {
            color: #003049;
            font-family: Lato-Bold;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
          }

          p {
            color: #767676;
            font-family: Lato-Regular;
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 6px;
          }

          h2 {
            color: #003049;
            font-family: Co Headline Bold;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 0;
          }

          .plus-icon {
            position: absolute;
            bottom: 10px;
            left: 97%;
            transform: translateX(-50%);
            background-color: var(--white-bg);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-weight: 400;
            color: rgb(0, 0, 0);
            box-shadow: 0px 1px 3px #00000029;
            cursor: pointer;
            right: 0;
            top: 16px;

            img {
              padding: 9px;
              width: 36px;
            }
          }

          .offer-img {
            position: relative;
          }

          .delete_icon {
            height: 24px;

            img {
              width: 15px;
              height: 15px;
              border-radius: 0;
            }
          }

          .minus_icon {
            img {
              width: 14px;
              height: 32px;
              margin: 0px 10px;
            }
          }
        }

        &:nth-child(3) {
          .text-cotainer {
            button {
              &.btn-primary {
                background-color: #f77f00;
              }
            }
          }
        }

        &:nth-child(4) {
          .text-cotainer {
            button {
              &.btn-primary {
                background-color: #fcbf49;
              }
            }
          }
        }

        .offer-img {
          img {
            width: 145px;
            height: 128px;
            border-radius: 0 15px 15px 0px;
          }
        }
      }

      &#order-again {
        .special-offers {
          h2 {
            margin: 22px 0 15px;
          }
        }
      }
    }
  }
}

.cookies-modal {
  .modal-dialog {
    width: 100%;
    position: absolute;
    /* Ensures it stays fixed */
    left: 0%;
    right: 0;
    bottom: 0;
    /* Adjust this as needed */
    transform: translate(0%, 0%) !important;
    /* Centers horizontally */
    display: flex;
    align-items: center;
    transition: bottom 0.9s ease-in-out !important;
    margin: 0 auto;
    max-width: 100% !important;

    .modal-content {
      border-radius: 25px 25px 0 0;
      background-color: #fff;
      margin: 0rem 0;
      border: 0;

      .modal-header {
        border-bottom: 0;
        justify-content: space-between;
        align-items: center;

        button {
          border: 0;
          background-color: transparent;

          &.btn-close {
            background-image: url(../images/close-icon.svg);
            width: 18px;
            height: 18px;
            background-size: 18px;
            // -webkit-appearance: none;
            // filter: brightness(0) invert(1);
            padding: 0px;
            display: none;
          }

          &:focus-visible {
            outline: none;
            box-shadow: none;
            border: none;
          }

          &:focus {
            outline: none;
            box-shadow: none;
            border: none;
          }
        }
      }

      .modal-body {
        padding: 0rem 1.5rem 1rem;

        .cookies-modal-show {
          .container {
            padding: 0;
          }

          .text-content {
            h2 {
              font-size: 24px;
              color: var(--teal-bg);
              line-height: 30px;
              font-family: Co Headline Bold;
              margin-bottom: 14px;
            }

            p {
              font-size: 16px;
              color: var(--teal-bg);
              line-height: 21px;
              font-family: Lato-Bold;
              margin-bottom: 0px;
              font-weight: 400;
            }

            a {
              font-size: 14px;
              color: var(--teal-bg);
              line-height: 21px;
              font-family: Lato-Bold;
              margin-bottom: 0px;
              font-weight: 600;
              text-decoration: underline;
            }
          }

          .cookies-setting {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem 0;

            span {
              img {
                width: 20px;
                margin-right: 5px;
                // vertical-align: baseline;
              }
            }

            p {
              font-size: 20px;
              color: var(--teal-bg);
              line-height: 21px;
              font-family: Lato-Bold;
              margin-bottom: 0px;
              font-weight: 600;

            }
          }

          .group-btn {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            width: 50%;

            button {
              margin: 0px 0 15px;

              &.primary-btn {
                width: 49%;
                height: 50px;
                display: flex;
                justify-content: center;
                border-radius: 50px;
                border: 2px solid var(--teal-bg);
                color: var(--text-teal);
                font-family: Lato-Bold;
                font-size: 17px;
                align-items: center;
                box-shadow: 0px 3px 6px #00000029;
                margin: 0 auto;

                &.active {
                  background-color: var(--teal-bg);
                  color: var(--text-white);
                }

                &:hover {
                  background-color: var(--teal-bg);
                  color: var(--text-white);
                }

                &:focus-visible {
                  outline: none;
                  box-shadow: none;
                  border: none;
                }

                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 1522px) {
  .main-wrapper#ordering-menu-page .ordering-menu .tab.fixed {
    padding: 18px 13rem 18px 13rem !important;
    height: 78px;
  }
}

@media screen and (max-device-width: 1366px) {
  .main-wrapper#ordering-menu-page {
    .ordering-menu {
      .tab {
        &.fixed {
          padding: 17px 0 18px 121px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .main-wrapper#ordering-menu-page {
    .ordering-menu {
      .tab {
        width: auto;
        overflow-x: scroll;

        &.fixed {
          padding: 14px 0 0 15px !important;
        }
      }

      .order-menu-img {
        &::after {
          height: 38%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-wrapper#ordering-menu-page {
    .ordering-menu {
      .tab {
        width: auto;
        padding: 7px 0 !important;

        &.fixed {
          padding: 20px 0 20px 0px !important;
        }
      }

      .element {
        padding-top: 12px;
      }

      .order-container {
        ul {
          li {
            span {
              span.react-loading-skeleton {
                width: 167px !important;
              }
            }
          }
        }
      }

      .order-menu-img {
        &::after {
          height: 44%;
        }
      }

      #custom-navigation {
        ul {
          padding: 0 0 0 12px;
          height: 46px;

          li {
            &:hover {
              background-color: transparent !important;

              a {
                color: var(--text-teal) !important;

                &.active {
                  color: var(--text-teal) !important;
                }
              }
            }
          }
        }

        span {
          span.react-loading-skeleton {
            width: 93% !important;
            margin-bottom: 10px;
          }
        }
      }

      .view-order-section {
        .view-order-btn {
          width: 94%;
        }

        span {
          span.react-loading-skeleton {
            width: 94% !important;
          }
        }
      }

      .delivered-inner {
        .special-offer-boxs {
          .text-container {
            width: 68%;

            .plus-icon {
              left: 92%;

              img {
                -webkit-user-select: none;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
                pointer-events: none;
              }
            }

            .delete_icon {
              img {
                -webkit-user-select: none;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
                pointer-events: none;
              }
            }

            .minus_icon {
              img {
                -webkit-user-select: none;
                -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
                pointer-events: none;
              }
            }

            .right-side {
              span {
                span.react-loading-skeleton {
                  position: absolute;
                  top: 20px;
                  right: 10px;
                  border-radius: 2px !important;
                  width: 130px !important;
                }
              }
            }

            p {
              span {
                span.react-loading-skeleton {
                  margin-bottom: 0px;
                }
              }
            }

            span {
              span.react-loading-skeleton {
                margin-bottom: 5px;
                border-radius: 2px !important;
              }
            }

            .box-right {
              left: 83% !important;
            }
          }
        }
      }
    }
  }

  .cookies-modal {
    .modal-dialog {
      width: 100%;
      margin: 0;
      bottom: 0;
      position: absolute;
      .modal-content {
        .modal-body {
          .cookies-modal-show {
            .group-btn {
              display: block;
              width: 100%;
              button {
                 &.primary-btn {
                   width: 100%;
                   margin-bottom: 15px;
                 }
              }
            }
          }
        }
      }
    }
  }

  .main-wrapper#ordering-menu-page .ordering-menu .order-container h2 {
    margin: 3px 0 14px 0;
  }

  .main-wrapper#ordering-menu-page .ordering-menu .delivered-inner .special-offer-boxs .text-container h5 {
    font-size: 17px;
  }

  .main-wrapper#ordering-menu-page .ordering-menu .delivered-inner .special-offer-boxs .text-container h2 {
    font-size: 15px;
  }

  .main-wrapper#ordering-menu-page .ordering-menu .delivered-inner .special-offer-boxs .text-container p {
    width: 100%;
    line-height: 16px;
  }

  .main-wrapper#ordering-menu-page .ordering-menu .order-container .skeleton-empty {
    display: block !important;

    h2 {
      margin-right: 0 !important;

      span {
        span.react-loading-skeleton {
          width: 100% !important;
          height: 100px !important;
        }
      }

      &:nth-child(2) {
        span {
          span.react-loading-skeleton {
            height: 28px !important;
            width: 75% !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 430px) {
  .main-wrapper#ordering-menu-page .ordering-menu #custom-navigation ul li {
    width: 142px;
  }
}

@media only screen and (max-device-width: 390px) {
  .main-wrapper#ordering-menu-page .ordering-menu #custom-navigation ul li {
    width: 134px;
  }
}