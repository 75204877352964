.sign-up-modal {
    .modal-dialog {
        margin: 0rem auto;
        position: absolute;
        top: 0px;
        // bottom: 0;
        display: flex;
        left: 0;
        right: 0;
        width: 481px;
        justify-content: center;
        align-items: center;

        .modal-content {
            border-radius: 25px 25px;
            border: 0;
            padding: 1rem 1rem;
            background-color: #faf8f8;
            margin: 4rem auto;

            .modal-header {
                border-bottom: 0;
                padding: 10px 5px;
                align-items: end;
                justify-content: end;

                button {
                    background-color: transparent;

                    &.btn-close {
                        border: 0;
                        height: 12px;
                        width: 12px;
                        outline: 0;
                        background-size: 18px;
                        -webkit-mask-image: url(../images/cross-ic.svg);
                        mask-image: url(../images/cross-ic.svg);
                        background-color: var(--teal-bg);
                        padding: 6px;
                    }
                }
            }

            .modal-body {
                padding: 0;

                .main-wrapper {
                    .signup-page {
                        padding: 1rem 0 !important;
                        margin: 0 !important;

                        p {
                            font-size: 12px !important;
                        }
                    }
                }

                .verification-page {
                    padding: 20px 0 20px;

                    .resent-btn {
                        button {
                            &.primary-btn {
                                width: 85%;
                                margin: 0 auto 20px;

                            }
                        }
                    }

                    .group-btn {
                        width: 85%;
                        margin: 0 auto;
                        justify-content: center;

                        a {
                            button {
                                &.primary-btn {
                                    &.sm.Back {
                                        span {
                                            right: 77%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cookies-policy {
    padding-top: 15px;

    a {
        text-decoration: none;
        color: var(--teal-bg);
        margin-right: 10px;
        display: flex;
        align-items: anchor-center;

        img {
            filter: brightness(0) invert(0);
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background-size: 20px 20px;
            object-fit: contain;
        }
        &.email {
            padding: 10px 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .sign-up-modal {
        .modal-dialog {
            width: 96%;

            .modal-content {
                margin: 11rem auto;

                .modal-body {
                    .main-wrapper {
                        .signup-page {
                            padding: 1rem 0 !important;

                            p {
                                font-size: 14px !important;
                            }
                        }
                    }

                    .verification-page {
                        padding: 30px 0;

                        .resent-btn {
                            button {
                                &.primary-btn {
                                    width: 100%;
                                    margin: 0 auto 20px;
                                }
                            }
                        }

                        .group-btn {
                            width: 100%;
                            display: flex;
                            margin: 0 auto;
                            justify-content: center;

                            a {
                                button {
                                    &.primary-btn {
                                        &.sm.Back {
                                            span {
                                                right: 70%;
                                            }
                                        }

                                        &.Continue {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            button {
                                &.primary-btn {
                                    span {
                                        right: 16px;
                                    }

                                }
                            }
                        }

                        .verification-code {
                            .textBoxs {
                                input {
                                    width: 70px;
                                    height: 70px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}