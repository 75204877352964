.main-wrapper {
  &#cart-page {
    padding: 24px 0 110px;

    .close-icon {
      padding-bottom: 10px;
    }

    .your-cart-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        font-size: 23px;
        line-height: 30px;
        font-family: Co Headline Bold;
        color: var(--text-teal);
        margin-bottom: 0;
      }

      span {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .your-inner-box {
      border-bottom: 1px solid hsla(0, 0%, 10.38%, 0.15);
      padding-bottom: 30px;
      margin-bottom: 20px;

      .content {
        h4 {
          font-size: 17px;
          line-height: 30px;
          color: var(--text-teal);
          font-family: Co Headline Bold;
          margin-bottom: 5px;
        }

        h6 {
          font-size: 13px;
          line-height: 30px;
          color: #003049;
          font-family: Co Headline;
          margin-bottom: 1px;
        }

        p {
          font-size: 13px;
          line-height: 16px;
          color: #767676;
          margin-bottom: 15px;
        }
      }

      .box {
        display: flex;
        justify-content: space-between;

        .icon {
          background-color: #fff;
          box-shadow: 0px 1px 3px #00000029;
          border-radius: 50px;
          width: 108px;
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img {
            display: block;

            &.minus_icon {
              width: 35px !important;
              padding: 12px;
              -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
            }
            &.plus-icon {
              padding: 12px;
              width: 36px;
              -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
            }

            &.delete_icon {
              width: 35px !important;
              padding: 12px;
              -webkit-touch-callout: none;
                -webkit-tap-highlight-color: transparent;
            }
          }

          h3 {
            font-size: 18px;
            margin-bottom: 0;
            font-family: Lato-Bold;
            width: 21px;
            display: flex;
            justify-content: center;
          }

          .plus-icon {
            img {
              display: block;
            }
          }
        }

        h5 {
          font-size: 14px;
          line-height: 35px;
          color: #003049;
          font-family: Co Headline Bold;
          margin-bottom: 0px;
        }
      }
    }

    .inner-box {
      //   border-bottom: 1px solid hsla(0, 0%, 10.38%, 0.15);
      padding-bottom: 0px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      margin-top: -10px;

      .content {
        h6 {
          font-size: 13px;
          line-height: 16px;
          color: #767676;
          font-family: "Lato";
          margin-bottom: 0px;
        }

        p {
          font-size: 10px;
          line-height: 16px;
          color: #767676;
          margin-bottom: 0px;
        }
      }

      .box {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 64%;

        .icon {
          background-color: #fff0;
          box-shadow: 0px 1px 3px rgb(0 0 0 / 0%);

          img {
            display: block;
          }

          h3 {
            font-size: 16px;
            margin-bottom: 0;
          }

          .plus-icon {
            img {
              display: block;
            }
          }
        }

        h5 {
          font-size: 14px;
          line-height: 35px;
          color: #003049;
          font-family: Co Headline Bold;
          margin-bottom: 0px;
        }
      }
    }

    .add-order {
      background: #fff;
      border-radius: 10px;
      border: solid 0.25pt #707070;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 14px;

      .note {
        h6 {
          font-size: 14px;
          line-height: 30px;
          color: #003049;
          font-family: Co Headline Bold;
          margin-bottom: 0px;
        }

        p {
          color: #343434;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          font-family: Lato-Regular;
          margin-bottom: 6px;
        }
      }
    }

    .note-box-title {
      padding-bottom: 10px;

      strong {
        font-family: Co Headline Bold;
        color: var(--text-teal);
        font-size: 14px;
      }
    }

    .note-box {
      width: 100%;

      textarea {
        width: 100%;
        border: 1px solid hsla(0, 0%, 10.38%, 0.15);
        border-radius: 8px;
        padding: 0px 10px;
        outline: none;
      }
    }

    .subtotal-box {
      margin-top: 20px;
      border-top: 1px solid hsla(0, 0%, 10.38%, 0.15);

      .subtotal-box-content {
        display: flex;
        justify-content: space-between;
        padding: 14px 0;

        h4 {
          font-size: 14px;
          font-family: Lato-Bold;
          line-height: 30px;
          margin-bottom: 0;
        }
      }
    }

    .checkout-btn {
      padding-top: 30px;
      padding-bottom: 30px;
      background-color: var(--teal-bg);
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;

      .checkout-btn-to {
        width: 50%;
        margin: 0 auto;
        display: flex;

        .btn-primary {
          background-color: var(--white-bg);
          border-radius: 30px;
          box-shadow: 0px 1px 3px #00000029;
          color: var(--text-teal);
          // font-weight: 600;
          border: 0;
          font-size: 16px;
          line-height: 25px;
          font-family: Lato-Bold;
          height: 50px;
          width: 100%;
          outline: 0;
          margin-right: 20px;
        }
      }
    }
  }
}

.clear-modal {
  cursor: pointer;
  padding-left: 0 !important;
  bottom: 94px;
  position: sticky;

  .modal-dialog {
    margin: 1rem auto;
    // position: absolute;
    top: 0px;
    display: flex;
    left: 0;
    right: 0;
    width: 75%;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;

    .modal-content {
      border-radius: 25px 25px 0 0;
      border: 0;
      padding: 2rem 2rem;
      background-color: #faf8f8;
      box-shadow: 0px -5px 6px #00000029;

      .modal-header {
        border-bottom: 0;
        padding: 10px 0px;

        button {
          background-color: transparent;

          &.btn-close {
            background-image: url(../images/close-icon.svg);
            // -webkit-mask-image: url(../images/close-icon.svg);
            // mask-image: url(../images/close-icon.svg);
            border: 0;
            height: 17px;
            width: 17px;
            background-size: 17px;
            // background-color: var(--teal-bg);
            outline: 0;
            padding: 0;
          }
        }
      }

      .modal-body {
        padding: 0;

        .crear-content {
          .crear-box {
            border-bottom: 1px solid hsla(0, 0%, 10.38%, 0.15);
            display: flex;
            align-items: center;
            padding: 21px 0;
            &:last-child {
              border-bottom: 0;
              .crear-text {
                h3 {
                  color: #D62828;
                }
              }
              .crear-icon {
                img {
                  filter: invert(23%) sepia(98%) saturate(9712%) hue-rotate(0deg) brightness(80%) contrast(200%);
                }
              }
            }
            .crear-icon {
              margin-right: 1rem;
              img {
                width: 12px;
                height: 14px;
                vertical-align: baseline;
              }
            }
            .crear-text {
              h3 {
                font-size: 16px;
                // font-family: Co Headline Bold;
                color: #003049;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
// .modal-backdrop.show {
//   opacity: 0.0;
// }

@media only screen and (max-width: 767px) {
  .main-wrapper {
    &#cart-page {
      .checkout-btn {
        .checkout-btn-to {
          width: 100%;
          display: block;

          .btn-primary {
            margin-bottom: 15px;
            margin-right: 0;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .your-inner-box {
        .content {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .clear-modal {
    bottom: 96px;
  
    .modal-dialog {
      margin: 5rem auto;
      width: 100%;
      .modal-content {
        height: 175px;
        padding: 1rem 1.5rem;
        .modal-header {
          button {
            &.btn-close {
              height: 14px;
              width: 14px;
              background-size: 14px;
            }
          }
        }

        .modal-body {
          .crear-content {
            .crear-box {
              padding: 15px 0;
              .crear-text {
                h3 {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-device-width: 430px) {
  .main-wrapper {
    &#cart-page {
      padding: 24px 0 172px;
    }
  }
}
@media only screen and (max-device-width: 390px) {
  .main-wrapper {
    &#cart-page {
      padding: 24px 0 172px;
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .main-wrapper {
    &#cart-page {
      padding: 24px 0 172px;
    }
  }
}
