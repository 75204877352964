.main-wrapper {
    &#sign-up {
        background-color: #FAF8F8;

        .signup-page {
            padding: 9rem 0;
            margin: 0 19rem;

            .text-content {
                text-align: center;

                h2 {
                    font-family: Co Headline Bold;
                    font-size: 18px;
                    line-height: 30px;
                    color: var(--text-teal);
                    margin: 0 0 28px;
                }
            }

            .form-group-blue {
                width: 100%;
                margin-bottom: 26px;

                input {
                    box-shadow: 0px 1px 3px #00000029;
                    border-radius: 30px;
                    outline: none;
                    background-color: #fff;
                    height: 50px;
                    border: 0;
                    padding: 0 2rem;
                    font-size: 16px;
                    line-height: 32px;
                    width: 100%;
                    margin-bottom: 0px;
                    pointer-events: all;
                }
                input::placeholder {
                    color: #444444;
                    opacity: 1;
                    pointer-events: all !important;
                    -webkit-test-selection: all !important;
                }
                input:focus {
                    outline: none;
                    border-color: #000000;
                    box-shadow: 0px 1px 3px #00000029;
                }
                input:-webkit-autofill {
                    -webkit-text-fill-color: var(--text-black) !important;
                    -webkit-transition: background-color 9999s ease-in-out 0s;
                    transition: background-color 9999s ease-in-out 0s;
                }

                p {
                    text-align: left;
                    margin-top: 5px;
                    font-size: 14px;
                    margin-left: 15px;
                }

            }

            .Continue-btn {
                button {
                    &.primary-btn {
                        border-radius: 30px;
                        width: 100%;
                        height: 50px;
                        background-color: var(--teal-bg);
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                        color: var(--text-white);
                        border: 0;
                        font-size: 16px;
                        font-family: Lato-Bold;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        outline: none;

                        span {
                            position: absolute;
                            right: 22px;

                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            p {
                text-align: center;
                font-size: 11px;
                font-family: Lato-Regular;
                font-weight: 300;
                line-height: 20px;
                margin-top: 20px;
                color: #444444;

                span {
                    color: #003049;
                    font-weight: 600;
                    font-family: Lato-Bold;
                }
            }

            P.text-login {
                text-align: center;
                font-size: 14px;
                font-family: Lato-Regular;
                font-weight: 500;
                line-height: 25px;
                color: #444444;

                a {
                    text-decoration: none;
                    color: #003049;
                    font-weight: 600;
                    font-family: Lato-Bold;
                    margin-left: 5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .main-wrapper#sign-up {
      .signup-page {
          margin: 0 4rem;
      }
    }
  }


@media only screen and (max-width: 767px) {
    .main-wrapper {
        &#sign-up {
            .signup-page {
                margin: 0 0rem;
            }
        }
    }
}